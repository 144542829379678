import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Portfolio = (props) => {

  useEffect(() => {
    document.title = "Mg - Portfolio"
  }, []);

  return (
    <div className="full-width-container" style={{backgroundColor: "#FFFFFF"}}>
      <div className="hero-row-no-reverse" style={{backgroundColor: "#EFF1F3"}}>
        <img src="./images/portfolio-hero.png" className="about-hero-image" alt="A collection of mobile devices showing different app designs" />
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#EFF1F3", paddingTop: "48px", paddingBottom: "48px", paddingLeft: "36px", paddingRight: "36px", flexGrow: 1}}>
          <div className="headline-1" style={{textAlign: "center", maxWidth: "600px"}}>
            {"From business critical to just for fun."}
          </div>
          <div className="subheadline" style={{textAlign: "center", maxWidth: "600px"}}>
            {"Explore a collection of selected products that showcase Matt's approach to design."}
          </div>
        </div>
      </div>

      <div className="portfolio-feature-bar">
        <div className="feature-bar-single-column">
          <div className="feature-bar-tag">
            {"Lead Designer"}
          </div>
          <div className="headline-2" style={{color: "#FFFFFF"}}>
            {"Faces and Places / Guide"}
          </div>
          <div className="content-text" style={{color: "#FFFFFF", marginTop: "24px", marginBottom: "48px", paddingLeft: "0px"}}>
            {"Guide, formerly Faces and Places, is a new app intended to help associates "}
            {"connect with and navigate Walmart's Home Office campus in Bentonville, Arkansas."}
          </div>
          <Link to="/portfolio/faces-and-places-guide" className="primary-button">
            {"Learn More"}
            <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24}}>
              {"arrow_right_alt"}
            </i>
          </Link>
        </div>
        <div className="feature-bar-single-column">
          <img src="./images/portfolio-feature-fandp.png" style={{width: "100%"}} alt="Faces and Places running on a laptop" />
        </div>
      </div>

      <div className="portfolio-row">
        <div className="blank-row" />
        <div className="max-width-container">
          <div className="portfolio-inner-row">
            <img src="./images/Catfight-ImpossibleDecisions.png" className="portfolio-img" alt="Catfight running on a mobile device"/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", width: "100%", marginTop: "24px", paddingLeft: "36px", paddingRight: "36px"}}>
              <div className="feature-bar-tag" style={{color: "#AF125A"}}>
                {"Designer / Developer"}
              </div>
              <div className="headline-2" style={{textAlign: "left"}}>
                {"Catfight"}
              </div>
              <div className="content-text" style={{paddingTop: "6px", paddingLeft: "0px", paddingRight: "0px"}}>
                {"Every three minutes, Catfight asks you to make an impossible decision: which adoptable cat is cuter?"}
              </div>
              <Link to="/products/catfight" className="secondary-button" style={{marginTop: "24px", marginBottom: "24px"}}>
                {"Learn More"}
                <i className="material-icons-round" style={{fontSize: 24, color: "#2A324B"}}>
                  {"arrow_right_alt"}
                </i>
              </Link>
            </div>
          </div>
          <div className="blank-row" />
        </div>
      </div>

      <div className="portfolio-row" style={{backgroundColor: "#EFF1F3"}}>
        <div className="blank-row" />
        <div className="max-width-container">
          <div className="portfolio-inner-row">
            <img src="./images/portfolio-AskOuija-Welcome.jpg" className="portfolio-img" alt="Ask Ouija running on a mobile device"/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", width: "100%", marginTop: "24px", paddingLeft: "36px", paddingRight: "36px"}}>
              <div className="feature-bar-tag" style={{color: "#AF125A"}}>
                {"Designer / Developer"}
              </div>
              <div className="headline-2" style={{textAlign: "left"}}>
                {"Ask Ouija"}
              </div>
              <div className="content-text" style={{paddingTop: "6px", paddingLeft: "0px", paddingRight: "0px"}}>
                {"Gather your friends and ask the Spirits your most pressing questions. Your friends will give you the answers... one letter at a time."}
              </div>
              <Link to="/ask-ouija" className="secondary-button" style={{marginTop: "24px", marginBottom: "24px"}}>
                {"Learn More"}
                <i className="material-icons-round" style={{fontSize: 24, color: "#2A324B"}}>
                  {"arrow_right_alt"}
                </i>
              </Link>
            </div>
          </div>
          <div className="blank-row" />
        </div>
      </div>
      <div className="blank-row" />
    </div>
  )
}

export default Portfolio;
