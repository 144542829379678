import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Articles = (props) => {

  useEffect(() => {
    document.title = "Mg - Articles"
  }, []);

  return (
    <div>
      <div className="full-width-container" style={{backgroundColor: "#FFFFFF"}}>
        <div className="hero-row-no-reverse" style={{backgroundColor: "#EFF1F3"}}>
          <img src="./images/articles-helpingpeople-hero.jpg" className="portfolio-hero-img" alt="People on a crowded subway are immersed in their smartphones"/>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#EFF1F3", padding: "48px", flexGrow: 1}}>
            <div className="headline-2" style={{textAlign: "left", maxWidth: "600px", color: "#202020"}}>
              {"Helping people should be a common-sense goal for our products. So why do we so often fail to make it a priority?"}
            </div>
            <div className="content-text" style={{textAlign: "left", maxWidth: "600px", color: "#202020", paddingLeft: "0px"}}>
              {"Our obsession with efficiency means we often end up treating our users like robots. "}
              {"Pretty designs often end up exposing our users to our ineffective internal processes. "}
              {"Actually helpful and innovative features seem to rank lower in priority than designing "}
              {"ways to solicit personal information."}
            </div>
            <Link to="/articles/helping-people-should-be-a-common-sense-goal" className="secondary-button" style={{marginTop: "24px", alignSelf: "flex-start"}}>
              {"Read More"}
              <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24}}>
                {"arrow_right_alt"}
              </i>
            </Link>
          </div>
        </div>

        <div className="hero-row-no-reverse" style={{backgroundColor: "#FFFFFF"}}>
          <img src="./images/articles-practicalapplication-hero.jpg" className="portfolio-hero-img" alt="A person uses string and pushpins to map relationships between paper printouts of screen designs" />
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#FFFFFF", padding: "48px", flexGrow: 1}}>
            <div className="headline-2" style={{textAlign: "left", maxWidth: "600px", color: "#202020"}}>
              {"Our practical application of the User Experience discipline is too narrow"}
            </div>
            <div className="content-text" style={{textAlign: "left", maxWidth: "600px", color: "#202020", paddingLeft: "0px"}}>
              {"Businesses are big and complex. By focusing our energy on designing beautiful and user-friendly "}
              {"interfaces, and ignoring the rest of our business processes, we can actually make it easier for our "}
              {"users to have bad experiences."}
            </div>
            <Link to="/articles/our-practical-application-of-ux-is-too-narrow" className="secondary-button" style={{marginTop: "24px", alignSelf: "flex-start"}}>
              {"Read More"}
              <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24}}>
                {"arrow_right_alt"}
              </i>
            </Link>
          </div>
        </div>

        <div className="hero-row-no-reverse" style={{backgroundColor: "#EFF1F3"}}>
          <img src="./images/articles-peoplenotusers-hero.jpg" className="portfolio-hero-img" alt="A crowd of people cross a busy street" />
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#EFF1F3", padding: "48px", flexGrow: 1}}>
            <div className="headline-2" style={{textAlign: "left", maxWidth: "600px", color: "#202020"}}>
              {"We're here to serve people, not users"}
            </div>
            <div className="content-text" style={{textAlign: "left", maxWidth: "600px", color: "#202020", paddingLeft: "0px"}}>
              {"Making it easier for people to connect also means making people more vulnerable. Our products store "}
              {"vast amounts of information about people, some of whom have never even used our products. What's "}
              {"good for a user immediately may not be good for humans long-term."}
            </div>
            <Link to="/articles/serve-people-not-users" className="secondary-button" style={{marginTop: "24px", alignSelf: "flex-start"}}>
              {"Read More"}
              <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24}}>
                {"arrow_right_alt"}
              </i>
            </Link>
          </div>
        </div>

        <div className="hero-row-no-reverse" style={{backgroundColor: "#FFFFFF"}}>
          <img src="./images/articles-respectoverdelight-hero.jpg" className="portfolio-hero-img" alt="A man works at a desk in front of a window overlooking a sunset" />
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#FFFFFF", padding: "48px", flexGrow: 1}}>
            <div className="headline-2" style={{textAlign: "left", maxWidth: "600px", color: "#202020"}}>
              {"Focus on delivering respectful experiences, not delightful experiences"}
            </div>
            <div className="content-text" style={{textAlign: "left", maxWidth: "600px", color: "#202020", paddingLeft: "0px"}}>
              {"Too often, users have to resort to begging corporate handles on social media to resolve issues caused "}
              {"by ineffective systems, automation gone haywire, and outdated policies. The way we treat people matters "}
              {", and we should be anticipating the worst of human behavior when we design our products."}
            </div>
            <Link to="/articles/focus-on-respectful-not-delightful" className="secondary-button" style={{marginTop: "24px", alignSelf: "flex-start"}}>
              {"Read More"}
              <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24}}>
                {"arrow_right_alt"}
              </i>
            </Link>
          </div>
        </div>

        <div className="hero-row-no-reverse" style={{backgroundColor: "#EFF1F3"}}>
          <img src="./images/articles-dothemostgood-hero.jpg" className="portfolio-hero-img" alt="A man walks down a sidewalk in front of a brick wall that has painted upon it the phrase 'Do Good'" />
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#EFF1F3", padding: "48px", flexGrow: 1}}>
            <div className="headline-2" style={{textAlign: "left", maxWidth: "600px", color: "#202020"}}>
              {"Doing the most good"}
            </div>
            <div className="content-text" style={{textAlign: "left", maxWidth: "600px", color: "#202020", paddingLeft: "0px"}}>
              {"Case studies show increased employee engagement when we help them connect their jobs to a higher purpose. "}
              {"But when we tell our employees that they can change the world, do we really mean it, or are we just "}
              {"trying to get more out of them? Holding up our end of the bargain means giving workers an mount of "}
              {"trust that may be uncomfortable at first."}
            </div>
            <Link to="/articles/doing-the-most-good" className="secondary-button" style={{marginTop: "24px", alignSelf: "flex-start"}}>
              {"Read More"}
              <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24}}>
                {"arrow_right_alt"}
              </i>
            </Link>
          </div>
        </div>

        <div className="hero-row-no-reverse" style={{backgroundColor: "#FFFFFF", marginBottom: "95px"}}>
          <img src="./images/articles-notbeingevil-hero.jpg" className="portfolio-hero-img" alt="A trolley drives down a street" />
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#FFFFFF", padding: "48px", flexGrow: 1}}>
            <div className="headline-2" style={{textAlign: "left", maxWidth: "600px", color: "#202020"}}>
              {"On not being evil"}
            </div>
            <div className="content-text" style={{textAlign: "left", maxWidth: "600px", color: "#202020", paddingLeft: "0px"}}>
              {"In an increasingly connected and complex world, even the right choices can have unintended consequences. "}
              {"Perfection is an impossibility, but it doesn't mean we shouldn't try to be better."}
            </div>
            <Link to="/articles/on-not-being-evil" className="secondary-button" style={{marginTop: "24px", alignSelf: "flex-start"}}>
              {"Read More"}
              <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24}}>
                {"arrow_right_alt"}
              </i>
            </Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Articles
