import * as Firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const prodConfig = {
  apiKey: "AIzaSyCqsQ9yZ8uc_eUGhvXLn8wWM9tzGsvehPU",
  authDomain: "mghome-e2624.firebaseapp.com",
  databaseURL: "https://mghome-e2624.firebaseio.com",
  projectId: "mghome-e2624",
  storageBucket: "mghome-e2624.appspot.com",
  messagingSenderId: "903048864444",
  appId: "1:903048864444:web:63fd8141e6ee1d18d7a6b9",
  measurementId: "G-19M2XZNHLS"
};

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

class Fbase {
  constructor() {
    this.app = Firebase.initializeApp(prodConfig);
    this.analytics = Firebase.analytics();
    this.auth = Firebase.auth();
    this.firestore = Firebase.firestore();
  }

  doSignIn = () => {
    return this.auth.signInAnonymously()
    .then(authUser => {
      return authUser;
    })
  };

  doSignOut = () => {
    this.auth.signOut();
  };

  updateUserDisplayName(displayName) {
    return this.auth.currentUser.updateProfile({
      displayName,
    })
    .then(() => {
      return true;
    })
    .catch(err => {
      return { hasError: true, value: err };
    });
  }

  generateGameCode() {
    let gameCode = '';
    for (var x=0; x < 4; x++) {
      gameCode+= letters[Math.floor(Math.random() * (25 - 0 + 1) + 0)];
    }
    return this.checkCode(gameCode);
  }

  checkCode(gameCode) {
    let db = this.firestore;
    return db.collection("ao-games").where("gameCode", "==", gameCode)
    .get()
    .then(results => {
      if (results.size > 0) {
        return this.generateGameCode();
      } else {
        return this.createGame(gameCode);
      }
    })
    .catch(err => {
      console.log(err);
      return { hasError: true, error: err };
    });
  }

  ctCheckCode(matchCode) {
    let db = this.firestore;
    return db.collection("ct-matches").doc(matchCode.toUpperCase()).get().then(doc => {
      if (doc.exists) {
        return true;
      } else {
        return false;
      }
    });
  }

  ctCreateMatch(matchCode) {
    let db = this.firestore;
    return db.collection("ct-matches").doc(matchCode.toUpperCase()).set({
      status: "waiting",
      user1: Firebase.auth().currentUser.uid,
      user2: null,
      restaurant: null,
      user1Likes: null,
      user2Likes: null,
    })
    .then(() => {
      return { hasError: false }
    })
    .catch(e => {
      return { hasError: true, error: e }
    });
  };

  createGame(gameCode) {
    let db = this.firestore;
    return db.collection("ao-games").add({
      gameCode: gameCode,
      status: "waiting",
      players: [],
      owner: this.auth.currentUser.uid,
      answer: '',
      question: '',
    })
    .then(gameDoc => {
      return gameDoc.id;
    })
    .catch(err => {
      console.log(err);
      return { hasError: true, error: err };
    });
  }

  updatePlayers(gameDocID, playersArray) {
    let db = this.firestore;
    let gameRef = db.collection("ao-games").doc(gameDocID);
    return gameRef.update({ players: playersArray })
      .then(() => { return { hasError: false, value: null } })
      .catch(err => { return { hasError: true, value: err } });
  }

  getPlayers(gameDocID) {
    let db = this.firestore;
    let gameRef = db.collection("ao-games").doc(gameDocID);
    return gameRef.get().then(doc => {
      if (doc.exists) {
        return doc.data().players;
      } else {
        return { hasError: true, value: 'doc-not-found'};
      }
    })
    .catch(err => {
      return { hasError: true, value: err };
    });
  }

  getAvatar() {
    var db = this.firestore;
    var key = db.collection('ao-avatars').doc().id;
    return db.collection('ao-avatars').where(Firebase.firestore.FieldPath.documentId(), '>=', key).limit(1).get()
      .then(snapshot => {
        if (snapshot.size > 0) {
          var avatar;
          snapshot.forEach(doc => {
            avatar = doc.data().avatarText;
          });
          return avatar;
        } else {
          return db.collection('ao-avatars').where(Firebase.firestore.FieldPath.documentId(), '<', key).limit(1).get()
            .then(snapshot => {
              var avatar;
              snapshot.forEach(doc => {
                avatar = doc.data().avatarText;
              });
              return avatar;
            })
            .catch(err => {
              return { hasError: true, error: err };
            });
        }
      })
      .catch(err => {
        return { hasError: true, error: err };
      });
  }

  joinGame(gameCode) {
    var db = this.firestore;
    return db.collection("ao-games").where("gameCode", "==", gameCode)
    .get()
    .then(results => {
      if (results.size > 0) {
        let gameDocID = null;
        results.forEach(game => {
          gameDocID = game.id;
        });
        return { hasError: false, value: gameDocID };
      } else {
        return { hasError: true, value: 'game-not-found'};
      }
    })
    .catch(err => {
      return { hasError: true, value: err }
    });
  }

  getRandomPlayer(players) {
    return players[Math.floor(Math.random() * ((players.length - 1) - 0 + 1) + 0)];
  }

  startGame(gameID) {
    var db = this.firestore;
    return this.getPlayers(gameID)
      .then(players => {
        let questionAsker = { displayName: '', uid: '', avatar: '' };
        let answeringSpirit = { displayName: '', uid: '', avatar: '' };
        while (questionAsker.uid === answeringSpirit.uid) {
          console.log('getting roles');
          questionAsker = this.getRandomPlayer(players);
          answeringSpirit = this.getRandomPlayer(players);
        }
        return db.collection("ao-games").doc(gameID).update({
          status: "playing",
          questionAsker: questionAsker,
          answeringSpirit: answeringSpirit,
          question: '',
          answer: '',
        })
        .then(() => {
          return { hasError: false, value: null };
        })
        .catch(err => {
          return { hasError: true, value: err };
        });
      })
      .catch(err => {
        return { hasError: true, value: err }
      });
  }

  getQuestion() {
    var db = this.firestore;
    var key = db.collection('ao-questions').doc().id;
    return db.collection('ao-questions').where(Firebase.firestore.FieldPath.documentId(), '>=', key).limit(1).get()
      .then(snapshot => {
        if (snapshot.size > 0) {
          var question;
          snapshot.forEach(doc => {
            question = doc.data().questionText;
          });
          return question;
        } else {
          return db.collection('ao-questions').where(Firebase.firestore.FieldPath.documentId(), '<', key).limit(1).get()
            .then(snapshot => {
              var question;
              snapshot.forEach(doc => {
                question = doc.data().questionText;
              });
              return question;
            })
            .catch(err => {
              return { hasError: true, error: err };
            });
        }
      })
      .catch(err => {
        return { hasError: true, error: err };
      });
  }

  updateQuestion(gameID, question) {
    var db = this.firestore;
    return db.collection('ao-games').doc(gameID).update({
      question: question,
    })
    .then(() => {
      return { hasError: false, value: null };
    })
    .catch(err => {
      return { hasError: true, value: err };
    });
  }

  updateAnswer(gameID, answer) {
    var db = this.firestore;
    return db.collection('ao-games').doc(gameID).update({
      answer: answer,
    })
    .then(() => {
      return { hasError: false, value: null };
    })
    .catch(err => {
      return { hasError: true, value: err };
    });
  }

  finalizeAnswer(gameID, gameData) {
    var db = this.firestore;
    return db.collection('ao-answered-questions').add({
      gameID: gameID,
      gameCode: gameData.gameCode,
      questionAsker: gameData.questionAsker,
      question: gameData.question,
      answer: gameData.answer,
    })
    .then(() => {
      return { hasError: false, value: null }
    })
    .catch(err => {
      return { hasError: true, value: null }
    })
  }

  updateAnsweringSpirit(gameID, newAnsweringSpirit) {
    var db = this.firestore;
    return db.collection('ao-games').doc(gameID).update({
      answeringSpirit: newAnsweringSpirit,
    })
    .then(() => {
      return { hasError: false, value: null };
    })
    .catch(err => {
      return { hasError: true, value: err };
    });
  }


}

export default Fbase;
