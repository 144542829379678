import React, { useState } from 'react';
import Menu from '../Menu';

const Header = (props) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  }

  if ((props.ActiveItem === '/ask-ouija') || (props.ActiveItem === '/chicken-tender')) {
    return null;
  }

  return (
      <div className="header-row">
        <div className="header-container">
          <div className="menu-button" onClick={() => toggleMenu()}>
            <i className="material-icons-round" style={{color: "#FCA311", fontSize: 24}}>
              {"menu"}
            </i>
          </div>
          <div className="logo-letters">
            Mg
          </div>
        </div>
        <Menu Active={props.ActiveItem} MenuVisible={menuVisible} ToggleMenu={toggleMenu} />
        <a href="mailto:matt@hellomattgoodman.com" className="contact-button-header">
          {"contact"}
        </a>
      </div>
  )
};

export default Header;
