import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const Project = (props) => {
  const [thisProject, setThisProject] = useState(null);
  var { projectSlug } = useParams();

  console.log(projectSlug);

  useEffect(() => {
    loadProject();
  }, [projectSlug]);

  const loadProject = async () => {
    const projects = await import('../../constants/portfolio/' + projectSlug + '.js');
    setThisProject(projects.PROJECTS.project[0]);
    document.title = "Mg - Portfolio - " + projects.PROJECTS.project[0].title;
  }

  if (!thisProject) {
    return null;
  }

  return (
    <div>
      <div className="full-width-container" style={{backgroundColor: "#FFFFFF"}}>
        <div className="hero-row-no-reverse" style={{backgroundColor: "#EFF1F3"}}>
          <img src={thisProject.hero} className="portfolio-hero-img" alt="Header image" />
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#EFF1F3", padding: "48px", flexGrow: 1}}>
            <div className="headline-1" style={{textAlign: "center", maxWidth: "600px", color: "#202020"}}>
              {thisProject ? thisProject.title : null}
            </div>
            <div className="subheadline" style={{textAlign: "center", maxWidth: "600px", color: "#202020"}}>
              {thisProject ? thisProject.tag : null}
            </div>
          </div>
        </div>
        <div className="max-width-container" style={{paddingTop: "48px", paddingRight: "12px", paddingLeft: "12px"}}>
          <Link to="/portfolio" className="flat-button" style={{marginBottom: "24px"}}>
            <i className="material-icons-round" style={{color: "#009FB7", fontSize: "24px", marginRight: "6px"}}>arrow_back</i>
            Back to portfolio
          </Link>
          <div className="content-text" style={{marginTop: "48px"}}>
            <div dangerouslySetInnerHTML={{__html: thisProject ? thisProject.sections[0].text : null }} />
            {thisProject ? (thisProject.sections.map((section, index) => (
              index > 0 ? (
                <div key={index}>
                  <div className="headline-3">{section.title}</div>
                  <div dangerouslySetInnerHTML={{__html: section.text}} />
                </div>
              ) : null
            ))) : null}
          </div>
          <Link to="/portfolio" className="flat-button" style={{marginTop: "24px", marginBottom: "96px"}}>
            <i className="material-icons-round" style={{color: "#009FB7", fontSize: "24px", marginRight: "6px"}}>arrow_back</i>
            Back to portfolio
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Project;
