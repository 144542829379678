import React, { useEffect } from 'react';
import { useLocation, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import Home from '../Home';
import Header from '../Header';
import About from '../About';
import Portfolio from '../Portfolio';
import Articles from '../Articles';
import Article from '../Article';
import Project from '../Project';
import PrivacyCatfight from '../privacy-catfight';
import Catfight from '../Catfight';
import TutorialOverview from '../tutorial-overview';
import TutorialLesson from '../tutorial-lesson';
import AskOuija from '../ask-ouija';
import ChickenTender from '../chicken-tender';
import MobileDevelopmentCourse from '../mobile-development-course';
import { withFirebase } from '../Firebase';

const AppBase = (props) => {
  let location = useLocation();
  const ScrollToTop = () => {
    window.scrollTo(0,0);
    return null;
  }

  useEffect(() => {
    props.firebase.analytics.logEvent('page_view');
  }, [location, props.firebase.analytics]);

  return (
    <div style={{height: "100%"}}>
      <Header ActiveItem={location.pathname} />
      <Route component={ScrollToTop} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/about" component={About} />
        <Route path="/portfolio/:projectSlug" component={Project} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route path="/articles/:articleSlug" component={Article} />
        <Route exact path="/articles" component={Articles} />
        <Route exact path="/mobile-development-course" component={MobileDevelopmentCourse} />
        <Route exact path="/developing-together/:tutorialSlug" component={TutorialOverview} />
        <Route path="/developing-together/:tutorialSlug/:lessonSlug" component={TutorialLesson} />
        <Route path="/ask-ouija" component={AskOuija} />
        <Route path="/chicken-tender" component={ChickenTender} />
        <Route exact path="/catfight-privacy-policy" component={PrivacyCatfight} />
        <Route exact path="/products/catfight" component={Catfight} />
      </Switch>
      { ((location.pathname === "/chicken-tender") || (location.pathname === "/ask-ouija")) ? null : (
        <div className="footer">
          <div className="logo-letters" style={{color: "#7F8493"}}>
            {"Mg"}
          </div>
        </div>
      )}
    </div>
  )
}

const App = withFirebase(AppBase);
export default App;
