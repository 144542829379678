import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const TutorialLesson = (props) => {
  const [thisLesson, setThisLesson] = useState(null);
  const { tutorialSlug, lessonSlug } = useParams();

  useEffect(() => {
    loadLesson();
  }, [tutorialSlug, lessonSlug]);

  const loadLesson = async () => {
    const lessons = await import('../../constants/' + tutorialSlug + '/' + lessonSlug + '.js');
    setThisLesson(lessons.LESSONS.lesson[0]);
  }

  if (!thisLesson) {
    return null;
  }

  return (
    <div className="full-width-container">
      <div className="hero-row-no-reverse" />

      <div className="full-width-container" style={{backgroundColor: "#FFFFFF"}}>
        <div className="max-width-container" style={{marginTop: "48px", paddingRight: "12px", paddingLeft: "12px"}}>
          <Link to={"/developing-together/" + tutorialSlug} className="flat-button" style={{marginBottom: "48px"}}>
            <i className="material-icons-round" style={{color: "#009FB7", fontSize: "24px", marginRight: "6px"}}>
              arrow_back
            </i>
            Back to project overview
          </Link>

          <div className="headline-1">
            {thisLesson.project_title}
          </div>
          <div className="headline-3">
            {thisLesson.lesson_title}
          </div>
          <div className="progress-meter-container">
            <div style={{width: thisLesson.progress_meter, backgroundColor: thisLesson.accent_color, height: "12px"}} />
            <div style={{backgroundColor: "#EFF1F3", height: "12px", flexGrow: 1}} />
          </div>

          <div style={{marginTop: "36px", paddingLeft: "12px", paddingRight: "12px"}} dangerouslySetInnerHTML={{ __html: thisLesson.lesson_text }} />

          <div style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: thisLesson.navigation_buttons.length > 1 ? "space-between" : "flex-end", marginTop: "60px", marginBottom: "96px"}}>
            {thisLesson.navigation_buttons.map((link, index) => (
              <Link key={index} to={"/developing-together/" + tutorialSlug + "/" + link.link_slug} className="flat-button">
                { (link.link_icon === 'arrow_forward') ? (
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    <span>{link.link_text}</span>
                    <i className="material-icons-round" style={{color: "#009FB7", fontSize: "24px", marginLeft: "6px"}}>
                      {link.link_icon}
                    </i>
                  </div>
                ) : (
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                      <i className="material-icons-round" style={{color: "#009FB7", fontSize: "24px", marginRight: "6px"}}>
                        {link.link_icon}
                      </i>
                      <span>{ link.link_text }</span>
                    </div>
                )}
              </Link>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
};

export default TutorialLesson;
