import React from 'react';
import { Link } from 'react-router-dom'

const Home = (props) => {
  return (
    <div className="full-width-container" style={{backgroundColor: "#FFFFFF"}}>
      <div className="hero-row" style={{backgroundColor: "#EFF1F3"}}>

          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#EFF1F3", padding: "48px", flexGrow: 1}}>
            <div className="headline-1" style={{textAlign: "center", maxWidth: "600px"}}>
              {"Matt Goodman designs technology for humanity."}
            </div>
            <div className="subheadline" style={{textAlign: "center", maxWidth: "600px"}}>
              {"Matt is a User Experience Designer and Software Engineer with more than a decade of industry experience."}
            </div>
          </div>
          <img src="./images/matt-home.jpg" className="home-hero-image" alt="Matt" />

      </div>
      <div className="feature-bar">

          <div className="feature-bar-short-description">
            <div className="headline-2" style={{color: "#FFFFFF"}}>
              {"Recent work"}
            </div>
            <div className="subheadline" style={{color: "#FFFFFF"}}>
              {"Take a look at some of the things that have been keeping me busy."}
            </div>
            <Link to="/portfolio" className="primary-button">
              {"View Portfolio"}
              <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24}}>
                {"arrow_right_alt"}
              </i>
            </Link>
          </div>
          <div className="recent-work-column">
            <Link to="/products/catfight" className="recent-work-container">
              <img src="./images/recent-work-catfight.png" className="recent-work-image" alt="A screenshot of the Catfight app showing an active event" />
              <div className="recent-work-text-container">
                <div>
                  {"Every three minutes, "}
                  <span className="recent-work-text-highlight">{" Catfight "}</span>
                  {" asks you to make an impossible decision: which adoptable cat is cuter?"}
                </div>
              </div>
            </Link>

            <Link to="/ask-ouija" className="recent-work-container">
              <img src="./images/recent-work-ask-ouija.png" className="recent-work-image" alt="A screenshot of the Catfight app showing an active event" />
                <div className="recent-work-text-container">
                  <div>
                    {"Ask your friends questions in "}
                    <span className="recent-work-text-highlight">{" Ask Ouija "}</span>
                    {" and they'll provide the answers... one letter at a time."}
                  </div>
                </div>
            </Link>

          </div>

      </div>
      <div className="max-width-container" style={{paddingRight: "12px", paddingLeft: "12px"}}>
        <div className="blank-row" />
          <div className="headline-2" style={{textAlign: "center"}}>
            {"Let's do better"}
          </div>
          <div className="content-text">
            {"As the tech industry continues to invest in algorithms, artificial intelligence, "}
            {"and automation, humanity is being stripped from our products. We pretend that our "}
            {"algorithmic newsfeeds aren't designed just to serve our users more ads, that our "}
            {"artificial intelligence isn't being trained against biased datasets, and that "}
            {"automation is a perfect replacement for human intervention. While each of these "}
            {"tools has its place, we must also accept that removing the human element from so "}
            {"much of the design and execution of our digital products means we end up with less "}
            {"humane outcomes."}
            <br /><br />

            {"As User Experience professionals, we sit in a crucial spot on our product teams and "}
            {"as such, have the unique ability to be a champion for humanity. Our purpose should be "}
            {"to do more than simply deliver delightful experiences. Our intent should be to respect "}
            {"our users as humans and to focus on doing the most good we can for all people: our users, "}
            {"our stakeholders, and the world at large. After all, humanity refers to more than just "}
            {"the human race. It also refers to the 'quality of being humane' and the ability to "}
            {"demonstrate kindness and benevolence."}
            <br /><br />

            <span className="content-text-bold">
            {"It's time to admit that the technology industry has lost its humanity. "}
            </span>
            {"Companies continue to hoard our data only for it to be leaked or sold to third parties. "}
            {"Artificial Intelligence algorithms continue to be trained against biased datasets, "}
            {"perpetuating centuries of discrimination. When we see society first as customers with "}
            {"money to give, rather than "}
            <Link to="/articles/helping-people-should-be-a-common-sense-goal" className="content-link">
              {"humans to help"}
            </Link>
            {", we strip humanity from the products we create. We can and must do better."}
            <br /><br />

            <span className="content-text-bold">
            {"It's time for the next evolution of User Experience. "}
            </span>
            {"The discipline of User Experience requires us to refocus our attention on the user, but "}
            {"it's application is still "}
            <Link to="/articles/our-practical-application-of-ux-is-too-narrow" className="content-link">
             {"too narrow"}
            </Link>
            {" and its potential under-appreciated. User Experience practitioners must become fierce advocates "}
            {"for the "}
            <Link to="/articles/serve-people-not-users" className="content-link">
              {"concerns of people"}
            </Link>
            {", not simply users, across every aspect of our service offerings."}
            <br /><br />

            <span className="content-text-bold">
            {"It's time to do the hard work of caring about people. "}
            </span>
            {"Auto-playing videos. Incessant notifications. Infinite scrolling. Data leaks. Harassment. At "}
            {"what point does easy to use become harfmul to use? And when do we hold ourselves accountable "}
            {"for the impacts our products have on society? "}
            <Link to="/articles/focus-on-respectful-not-delightful" className="content-link">
              {"People deserve better"}
            </Link>
            {", and they'll soon start demanding it."}
            <br /><br />

            <span className="content-text-bold">
            {"It's time to do what's right. "}
            </span>
            {"Companies already worth hundreds of billions of dollars are competing for the rights to build "}
            {"a ten billion dollar War Cloud. $80 billion has been invested in autonomous cars, which studies "}
            {"say could either reduce energy consumption in transportation by as much as 90%, or increase it by "}
            {"more than 200%. We're solving complex problems, but are we asking the right questions? A renewed "}
            {"emphasis on "}
            <Link to="/articles/doing-the-most-good" className="content-link">
              {"doing the most good"}
            </Link>
            {" will help us realize the true promise of technology."}
            <br /><br />

            <span className="content-text-bold">
            {"It's time for humanity in technology. "}
            </span>
            {"The reputation of the tech industry is in decline. Externally, we've abused the trust of our users "}
            {" and given up on "}
            <Link to="/articles/on-not-being-evil" className="content-link">
              {"not being evil"}
            </Link>
            {". Internally, we've failed to address issues such as harassment, developer crunch, and poor diversity. "}
            {"The current business environment makes it almost impossible to overcome these obstacles. It also "}
            {"demands we do, lest our companies cease to exist."}
          </div>
        <div className="blank-row" />
      </div>
      <div className="call-to-action-bar">
        <div className="max-width-container">
          <div className="headline-2" style={{color: "#FFFFFF", textAlign: "center"}}>
            {"It's time to work together."}
          </div>
          <div className="content-text" style={{color: "#FFFFFF"}}>
            {"Together, we can do better. If you're tired of doing things the same old way and are interested in "}
            {"charting a new path forward, why not say hello? I'm especially interested in working with nonprofits, "}
            {"small businesses, and other organizations which may otherwise struggle to have their User Experience "}
            {"and software development needs met. "}
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <a href="mailto:matt@hellomattgoodman.com" className="primary-button">
              {"say hello"}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Home;
