import React from 'react';
import { Link } from 'react-router-dom';

const Menu = (props) => {
  return (
    <div className={props.MenuVisible ? "menu-container-visible" : "menu-container-hidden"}>
      <div className="menu-close" onClick={() => props.ToggleMenu()}>
        <i className="material-icons-round" style={{color: "#FFFFFF", fontSize: 24}}>
          {"close"}
        </i>
      </div>
      <div className="menu-logo-letters">
        {"Mg"}
      </div>
      <div className={props.MenuVisible ? "menu-item-container-visible" : "menu-item-container-hidden"}>
        <Link to="/home" className="headernav" onClick={() => props.ToggleMenu()}>
          <div className={(props.Active.indexOf('/home') >= 0 ? "menu-item-active" : (props.Active === "/" ? "menu-item-active" : "menu-item"))}>
            <div className={(props.Active.indexOf('/home') >= 0 ? "menu-item-active-text" : (props.Active === "/" ? "menu-item-active-text" : "menu-item-text"))}>
              Home
            </div>
          </div>
        </Link>

        <Link to="/about" className="headernav" onClick={() => props.ToggleMenu()}>
          <div className={(props.Active.indexOf('/about') >= 0 ? "menu-item-active" : "menu-item")}>
            <div className={(props.Active.indexOf('/about') >= 0 ? "menu-item-active-text" : "menu-item-text")}>
              About
            </div>
          </div>
        </Link>

        <Link to="/portfolio" className="headernav" onClick={() => props.ToggleMenu()}>
          <div className={(props.Active.indexOf('/portfolio') >= 0 ? "menu-item-active" : "menu-item")}>
            <div className={(props.Active.indexOf('/portfolio') >= 0 ? "menu-item-active-text" : "menu-item-text")}>
              Portfolio
            </div>
          </div>
        </Link>

        <Link to="/articles" className="headernav" onClick={() => props.ToggleMenu()}>
          <div className={(props.Active.indexOf('/articles') >= 0 ? "menu-item-active" : "menu-item")}>
            <div className={(props.Active.indexOf('/articles') >= 0  ? "menu-item-active-text" : "menu-item-text")}>
              Articles
            </div>
          </div>
        </Link>

        <a href="mailto:matt@hellomattgoodman.com" className="contact-button-mobile">
          {"contact"}
        </a>
      </div>
    </div>
  )
};

export default Menu
