import React, { useState, useEffect, useRef } from 'react';
import { withFirebase } from '../Firebase';

const ChickenTenderBase = props => {
  const LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const [currentStep, setCurrentStep] = useState(null);
  const [maxDistance, setMaxDistance] = useState(40000);
  const [matchData, setMatchData] = useState(null);
  const [ctMatchCode, setCtMatchCode] = useState('');
  const [coords, setCoords] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  const [writeCompleted, setWriteCompleted] = useState(true);

  const offset = useRef(0);

  var subscriber = null;

  useEffect(() => {
    props.firebase.doSignIn()
    .catch(err => { alert(err)});

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        let userCoords = {};
        userCoords.latitude = position.coords.latitude;
        userCoords.longitude = position.coords.longitude;
        setCoords(userCoords);
      },
      err => {
        console.log(err);
        alert("We ran into an issue getting your location. Check your security and privacy settings and make sure your browser has access to location services.");
        setCurrentStep(null);
      },
      { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true });
    } else {
      alert("Chicken Tender needs access to your location so we can find nearby restaurants");
    }

    return (() => {
      if (subscriber !== null) {
        subscriber();
      }
    });
  }, []);

  useEffect(() => {
    if ((currentStep === "create-match") && (coords === null)) {
      alert("We need to be able to access your location so we can find restaurants around you. Please check your privacy and security settings and ensure your browser has access to location serivces.");
      setCurrentStep(null);
    }
    if (currentStep === "lobby") {
      generateCode();
    }
  }, [currentStep]);

  useEffect(() => {
    //check for user === user1, currentStep === lobby, etc.
    if ((matchData !== null) && (matchData.status === "waiting") && (matchData.user2 !== null) && (currentStep !== "getting-restaurants") && (writeCompleted)) {
      setCurrentStep('getting-restaurants');
      if (matchData.user1 === props.firebase.auth.currentUser.uid) {
        getRestaurants(offset.current);
      }
    }

    if ((matchData !== null) && (!writeCompleted)) {
      setCurrentStep("hold-up");
    } else if ((matchData !== null) && (matchData.status === "matching") && ((currentStep != "matching") && (currentStep != "matched"))) {
      setCurrentStep("matching");
    } else if ((matchData !== null) && (matchData.status === "matching") && (matchData.user1Likes) && (matchData.user2Likes)) {
      setCurrentStep("matched");
    } else if ((matchData !== null) && (matchData.status === "matching") && ((matchData.user1Likes === false) && (matchData.user2Likes === false) || ((matchData.user1Likes) && (matchData.user2Likes === false)) || ((matchData.user1Likes === false) && (matchData.user2Likes)))) {
      if (matchData.user1 === props.firebase.auth.currentUser.uid) {
        pickRandomRestaurant(restaurants);
      }
      if (currentStep !== "matching") {
        setCurrentStep("matching");
      }
    }
  }, [matchData, currentStep, writeCompleted]);

  const getRestaurants = () => {
    fetch('https://us-central1-catfight-dev.cloudfunctions.net/restaurantSearch?latitude=' + coords.latitude + '&longitude=' + coords.longitude + '&radius=' + maxDistance + '&offset=' + offset.current)
    .then(results => results.json())
    .then(data => {
      if (data.length > 0) {
        let tempRestaurants = [];
        data.forEach(restaurant => {
          let thisRestaurant = {};
          thisRestaurant.id = restaurant.id;
          thisRestaurant.name = restaurant.name;
          thisRestaurant.image_url = restaurant.image_url;
          thisRestaurant.url = restaurant.url;
          thisRestaurant.review_count = restaurant.review_count;
          thisRestaurant.categories = restaurant.categories;
          thisRestaurant.rating = restaurant.rating;
          thisRestaurant.location = restaurant.location;
          tempRestaurants.push(thisRestaurant);
        });
        setRestaurants(tempRestaurants);
        pickRandomRestaurant(tempRestaurants);
      }
    });
  }

  const pickRandomRestaurant = (someRestaurants) => {
    setWriteCompleted(false);
    if (someRestaurants === null) {
      someRestaurants = restaurants;
    }
    if (someRestaurants.length > 0) {
      let randomNumber = Math.round(0 + Math.random() * ((someRestaurants.length -1) -0));
      let randomRestaurant = someRestaurants[randomNumber];
      let restaurantArray = someRestaurants;
      restaurantArray.splice(randomNumber, 1);
      setRestaurants(restaurantArray);
      props.firebase.firestore.collection("ct-matches").doc(ctMatchCode).update({
        restaurant: randomRestaurant,
        user1Likes: null,
        user2Likes: null,
        status: "matching",
      })
      .then(() => {
        setWriteCompleted(true);
      });
    } else {
      offset.current = offset.current + 50;
      getRestaurants();
    }

  }

  const generateCode = () => {
    let generatedCode = '';
    while (generatedCode.length < 4) {
      let randomNumber = Math.round(0 + Math.random() * ((LETTERS.length - 1) -0));
      generatedCode+= LETTERS[randomNumber];
    }
    checkIfMatchExists(generatedCode);
  }

  const checkIfMatchExists = matchCode => {
    props.firebase.ctCheckCode(matchCode).then(resp => {
      resp ? generateCode() : createMatch(matchCode);
    });
  }

  const createMatch = matchCode => {
    props.firebase.ctCreateMatch(matchCode).then(resp => {
      if (!resp.hasError) {
        if (subscriber !== null) {
          subscriber();
        }
        setCtMatchCode(matchCode);
        createListener(matchCode);
      } else {
        alert("We ran into an issue trying to create the match. Please try again.");
        setCurrentStep(null);
      }
    })
  }

  const createListener = matchCode => {
    subscriber = props.firebase.firestore.collection("ct-matches").doc(matchCode).onSnapshot(doc => {
      if (doc.exists) {
        setMatchData(doc.data());
      } else {
        alert("We couldn't find a match with that match code");
        setCurrentStep(null);
      }
    })
  }

  const updateMatchCode = e => {
    if (e.target.value.length <= 4) {
      setCtMatchCode(e.target.value.toUpperCase());
    }
  }

  const joinMatch = () => {
    if (ctMatchCode.length !== 4) {
      alert("Match codes are 4 letters long. Please double check the match code.");
      return;
    }

    props.firebase.firestore.collection("ct-matches").doc(ctMatchCode).get().then(doc => {
      if (doc.exists) {
        if ((doc.data().user1 === props.firebase.auth.currentUser.uid) || (doc.data().user2 === props.firebase.auth.currentUser.uid)) {
          createListener(ctMatchCode);
        } else {
          props.firebase.ctCheckCode(ctMatchCode).then(resp => {
            if (resp) {
              props.firebase.firestore.collection("ct-matches").doc(ctMatchCode).update({
                user2: props.firebase.auth.currentUser.uid,
              })
              .then(() => {
                createListener(ctMatchCode)
              });
            } else {
              alert("We couldn't find a match with that code");
            }
          });
        }
      } else {
        alert("We couldn't find a match with that code");
      }
    });
  }

  const doDislike = () => {
    setWriteCompleted(false);
    if (matchData.user1 === props.firebase.auth.currentUser.uid) {
      props.firebase.firestore.collection("ct-matches").doc(ctMatchCode.toUpperCase()).update({
        user1Likes: false,
      })
      .then(() => {
        setWriteCompleted(true);
      })
      .catch(err => {
        props.firebase.firestore.collection("ct-matches").doc(ctMatchCode.toUpperCase()).update({
          errorReceived: err.toString(),
        })
        .then(() => {
          alert("Something went terribly wrong :(");
          setCurrentStep(null);
          setWriteCompleted(true);
        });
      });
    } else if (matchData.user2 === props.firebase.auth.currentUser.uid) {
      props.firebase.firestore.collection("ct-matches").doc(ctMatchCode.toUpperCase()).update({
        user2Likes: false,
      })
      .then(() => {
        setWriteCompleted(true);
      })
      .catch(err => {
        props.firebase.firestore.collection("ct-matches").doc(ctMatchCode.toUpperCase()).update({
          errorReceived: err.toString(),
        })
        .then(() => {
          alert("Something went terribly wrong :(");
          setCurrentStep(null);
          setWriteCompleted(true);
        });
      });
    }
  }

  const doLike = () => {
    setWriteCompleted(false);
    if (matchData.user1 === props.firebase.auth.currentUser.uid) {
      props.firebase.firestore.collection("ct-matches").doc(ctMatchCode.toUpperCase()).update({
        user1Likes: true,
      })
      .then(() => {
        setWriteCompleted(true);
      })
      .catch(err => {
        props.firebase.firestore.collection("ct-matches").doc(ctMatchCode.toUpperCase()).update({
          errorReceived: err.toString(),
        })
        .then(() => {
          alert("Something went terribly wrong :(");
          setCurrentStep(null);
          setWriteCompleted(true);
        });
      });
    } else if (matchData.user2 === props.firebase.auth.currentUser.uid) {
      props.firebase.firestore.collection("ct-matches").doc(ctMatchCode.toUpperCase()).update({
        user2Likes: true,
      })
      .then(() => {
        setWriteCompleted(true);
      })
      .catch(err => {
        props.firebase.firestore.collection("ct-matches").doc(ctMatchCode.toUpperCase()).update({
          errorReceived: err.toString(),
        })
        .then(() => {
          alert("Something went terribly wrong :(");
          setCurrentStep(null);
          setWriteCompleted(true);
        });
      });
    }
  }

  const checkIfVoted = () => {
    if (matchData.user1 === props.firebase.auth.currentUser.uid) {
      if (matchData.user1Likes === null) {
        return renderVoteBlock();
      } else if (matchData.user1Likes) {
        let messageText = "You said you'd be okay eating here. Let's see what your friend says."
        return renderSubmittedVoteBlock(messageText);
      } else if (!matchData.user1Likes) {
        let messageText = "You said you didn't want to eat here. Let's see what your friend says."
        return renderSubmittedVoteBlock(messageText);
      }

    } else if (matchData.user2 === props.firebase.auth.currentUser.uid) {
      if (matchData.user2Likes === null) {
        return renderVoteBlock();
      } else if (matchData.user2Likes) {
        let messageText = "You said you'd be okay eating here. Let's see what your friend says."
        return renderSubmittedVoteBlock(messageText);
      } else if (!matchData.user2Likes) {
        let messageText = "You said you didn't want to eat here. Let's see what your friend says."
        return renderSubmittedVoteBlock(messageText);
      }
    }
  }

  const renderVoteBlock = () => {
    return (
      <>
        <div style={{width: "100%", textAlign: "center", fontFamily: "Heebo", fontWeight: 900, color: "#AF125A", fontSize: "18px", letterSpacing: "0.03em", marginTop: "24px"}}>
          {"Do you want to eat here?"}
        </div>
        <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "12px"}}>
          <button style={{border: "none", width: "48px", height: "48px", borderRadius: "24px", backgroundColor: "#2A324B", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginLeft: "6px", marginRight: "6px", cursor: "pointer"}} onClick={() => doDislike()}>
            <i className="material-icons-round" style={{fontSize: "24px", color: "#FFFFFF"}}>
              thumb_down
            </i>
          </button>
          <button style={{border: "none", width: "48px", height: "48px", borderRadius: "24px", backgroundColor: "#2A324B", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginLeft: "6px", marginRight: "6px", cursor: "pointer"}} onClick={() => doLike()}>
            <i className="material-icons-round" style={{fontSize: "24px", color: "#FFFFFF"}}>
              thumb_up
            </i>
          </button>
        </div>
      </>
    )
  }

  const renderSubmittedVoteBlock = messageText => {
    return (
      <>
        <div style={{width: "100%", textAlign: "center", fontFamily: "Heebo", fontWeight: 900, color: "#AF125A", fontSize: "18px", letterSpacing: "0.03em", marginTop: "24px"}}>
          {"Your vote has been submitted"}
        </div>
        <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "12px", fontFamily: "Heebo", fontWeight: 300, color: "#202020", fontSize: "12px", letterSpacing: "0.03em", lineHeight: "22px", textAlign: "center"}}>
          {messageText}
        </div>
      </>
    )
  }

  const doAgain = () => {
    props.firebase.firestore.collection("ct-matches").doc(ctMatchCode).update({
      user1Likes: false,
      user2Likes: false,
    })
  }

  if (currentStep === null) {
    return (
      <div style={{display: "flex", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
        <div style={{display: "flex", height: "568px", width: "320px", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
          <img src="./images/ChickenTenderSplash.png" style={{width: '226px'}} alt="A table with a tablecloth, two glasses of wine, a wine bottle, and a plant. Chicken Tender: Meal Matchmaking" />
          <button onClick={() => setCurrentStep('create-match')} style={{marginTop: "36px", width: "212px", height: "48px", display: "flex", flexDirection:"row", alignItems: "center", justifyContent: "center", backgroundColor: "#FCA311", borderRadius: "24px", marginBottom: "12px", fontFamily: "Heebo", fontWeight: 700, fontSize: "14px", color: "#202020", textAlign: "center", letterSpacing: "0.03em", border: "none", cursor: "pointer"}}>
            Create Match
            <i className="material-icons-round" style={{color: "#202020", fontSize: "24px", marginRight: "6px"}}>chevron_right</i>
          </button>
          <button onClick={() => setCurrentStep('join-match')} style={{width: "212px", height: "48px", display: "flex", flexDirection:"row", alignItems: "center", justifyContent: "center", backgroundColor: "#FCA311", borderRadius: "24px", fontFamily: "Heebo", fontWeight: 700, fontSize: "14px", color: "#202020", textAlign: "center", letterSpacing: "0.03em", border: "none", cursor: "pointer"}}>
            Enter Match Code
            <i className="material-icons-round" style={{color: "#202020", fontSize: "24px", marginRight: "6px"}}>chevron_right</i>
          </button>
        </div>
      </div>
    );
  }

  if (currentStep === "hold-up") {
    return (
      <div style={{display: "flex", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
        <div style={{display: "flex", height: "568px", width: "320px", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
          <div className="cssload-dots">
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
          </div>

          <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="12" ></feGaussianBlur>
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0	0 1 0 0 0	0 0 1 0 0	0 0 0 18 -7" result="goo" ></feColorMatrix>

            </filter>
          </defs>
          </svg>
        </div>
      </div>
    );
  }

  if (currentStep === "create-match") {
    return (
      <div style={{display: "flex", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
        <div style={{display: "flex", height: "568px", width: "320px", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
          <img src="./images/ChickenTenderCar.png" style={{width: '272px'}} alt="An illustration of a car" />
          <div style={{width: "272px", fontFamily: "Heebo", fontWeight: 900, fontSize: "18px", color: "#FFFFFF", letterSpacing: "0.03em", textAlign: "center", marginTop: "12px"}}>
            {"How far are you willing to travel for tasty, tasty food?"}
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: "24px", marginRight: "24px", marginTop: "60px", marginBottom: "24px"}}>
            <button style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "60px", width: "60px", backgroundColor: maxDistance === 1610 ? "#AF125A" : "#EFF1F3", borderRadius: "30px", border: "none", cursor: "pointer"}} onClick={() => setMaxDistance(1610)}>
              <div style={{fontFamily: "Heebo", fontWeight: 900, fontSize: "18px", letterSpacing: "0.03em", color: maxDistance === 1610 ? "#FFFFFF" : "#202020", textAlign: "center"}}>
                {"<1"}
              </div>
              <div style={{fontFamily: "Heebo", fontWeight: 300, fontSize: "12px", letterSpacing: "0.03em", color: maxDistance === 1610 ? "#FFFFFF" : "#202020", textAlign: "center"}}>
                {"mile"}
              </div>
            </button>

            <button style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "60px", width: "60px", backgroundColor: maxDistance === 8047 ? "#AF125A" : "#EFF1F3", borderRadius: "30px", border: "none", cursor: "pointer"}} onClick={() => setMaxDistance(8047)}>
              <div style={{fontFamily: "Heebo", fontWeight: 900, fontSize: "18px", letterSpacing: "0.03em", color: maxDistance === 8047 ? "#FFFFFF" : "#202020", textAlign: "center"}}>
                {"<5"}
              </div>
              <div style={{fontFamily: "Heebo", fontWeight: 300, fontSize: "12px", letterSpacing: "0.03em", color: maxDistance === 8047 ? "#FFFFFF" : "#202020", textAlign: "center"}}>
                {"miles"}
              </div>
            </button>

            <button style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "60px", width: "60px", backgroundColor: maxDistance === 16093 ? "#AF125A" : "#EFF1F3", borderRadius: "30px", border: "none", cursor: "pointer"}} onClick={() => setMaxDistance(16093)}>
              <div style={{fontFamily: "Heebo", fontWeight: 900, fontSize: "18px", letterSpacing: "0.03em", color: maxDistance === 16093 ? "#FFFFFF" : "#202020", textAlign: "center"}}>
                {"<10"}
              </div>
              <div style={{fontFamily: "Heebo", fontWeight: 300, fontSize: "12px", letterSpacing: "0.03em", color: maxDistance === 16093 ? "#FFFFFF" : "#202020", textAlign: "center"}}>
                {"miles"}
              </div>
            </button>

            <button style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "60px", width: "60px", backgroundColor: maxDistance === 40000 ? "#AF125A" : "#EFF1F3", borderRadius: "30px", border: "none", cursor: "pointer"}} onClick={() => setMaxDistance(40000)}>
              <div style={{fontFamily: "Heebo", fontWeight: 900, fontSize: "18px", letterSpacing: "0.03em", color: maxDistance === 40000 ? "#FFFFFF" : "#202020", textAlign: "center"}}>
                {"<25"}
              </div>
              <div style={{fontFamily: "Heebo", fontWeight: 300, fontSize: "12px", letterSpacing: "0.03em", color: maxDistance === 40000 ? "#FFFFFF" : "#202020", textAlign: "center"}}>
                {"miles"}
              </div>
            </button>


          </div>
          <button onClick={() => setCurrentStep('lobby')} style={{width: "212px", height: "48px", display: "flex", flexDirection:"row", alignItems: "center", justifyContent: "center", backgroundColor: "#FCA311", borderRadius: "24px", fontFamily: "Heebo", fontWeight: 700, fontSize: "14px", color: "#202020", textAlign: "center", letterSpacing: "0.03em", border: "none", cursor: "pointer"}}>
            {"Let's Go"}
            <i className="material-icons-round" style={{color: "#202020", fontSize: "24px", marginRight: "6px"}}>chevron_right</i>
          </button>
        </div>
      </div>
    );
  }

  if (currentStep === "lobby") {
    return (
      <div style={{display: "flex", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
        <div style={{display: "flex", height: "568px", width: "320px", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
          <img src="./images/ChickenTenderLobby.png" style={{width: '212px'}} alt="An illustration of a soda and fries" />
          <div style={{width: "212px", fontFamily: "Heebo", fontWeight: 900, color: "#FFFFFF", fontSize: "18px", letterSpacing: "0.03em", textAlign: "center", marginTop: "12px"}}>
            {"Waiting for your friend to join"}
          </div>
          <div style={{width: "320px", height: "158px", background: "url('./images/ChickenTenderCodeBackground.png')", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "60px"}}>
            <div style={{fontFamily: "Heebo", fontWeight: 300, fontSize: "14px", letterSpacing: "0.03em", lineHeight: "22px", textAlign: "center", color: "#202020"}}>
              {"Have your friend enter this Match Code:"}
            </div>
            <div style={{fontFamily: "Heebo", fontWeight: 900, fontSize: "36px", letterSpacing: "0.03em", color: "#2A324B", textAlign: "center"}}>
              {ctMatchCode}
            </div>
          </div>
        </div>
      </div>
    )
  }

    if (currentStep === "join-match") {
      return (
        <div style={{display: "flex", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
          <div style={{display: "flex", height: "568px", width: "320px", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
            <img src="./images/ChickenTenderLobby.png" style={{width: '212px'}} alt="An illustration of a soda and fries" />
            <div style={{width: "212px", fontFamily: "Heebo", fontWeight: 900, color: "#FFFFFF", fontSize: "18px", letterSpacing: "0.03em", textAlign: "center", marginTop: "12px"}}>
              {"What's the Match Code?"}
            </div>
            <input
              style={{width: "212px", height: "48px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontFamily: "Heebo", fontWeight: 900, fontSize: "24px", color: "#202020", letterSpacing: "0.30em", textAlign: "center", backgroundColor: "#EFF1F3", marginTop: "6px", marginBottom: "36px"}}
              placeholder="????"
              value={ctMatchCode}
              onChange={e => updateMatchCode(e)} />
            <button onClick={() => joinMatch()} style={{width: "212px", height: "48px", display: "flex", flexDirection:"row", alignItems: "center", justifyContent: "center", backgroundColor: "#FCA311", borderRadius: "24px", fontFamily: "Heebo", fontWeight: 700, fontSize: "14px", color: "#202020", textAlign: "center", letterSpacing: "0.03em", border: "none", cursor: "pointer"}}>
              {"Let's Go"}
              <i className="material-icons-round" style={{color: "#202020", fontSize: "24px", marginRight: "6px"}}>chevron_right</i>
            </button>
          </div>
        </div>
      )
    }

    if (currentStep === "getting-restaurants") {
      return (
        <div style={{display: "flex", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
          <div style={{display: "flex", height: "568px", width: "320px", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
            <img src="./images/ChickenTenderSearching.png" style={{width: '212px'}} alt="An illustration of a soda and fries" />
            <div style={{width: "212px", fontFamily: "Heebo", fontWeight: 900, color: "#FFFFFF", fontSize: "18px", letterSpacing: "0.03em", textAlign: "center", marginTop: "12px"}}>
              {"Looking for restaurants near you..."}
            </div>
          </div>
        </div>
      )
    }

    if (currentStep === "matching") {
      return (
        <div style={{display: "flex", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
          <div style={{display: "flex", height: "568px", width: "320px", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
            {matchData.restaurant.image_url !== null ? (
              <div style={{width: "100%", height: "200px", overflow: "hidden", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "#202020"}}>
                <img style={{height: "100%", resizeMode: "cover"}} src={matchData.restaurant.image_url} />
              </div>
            ) : (
              null
            )}
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flexGrow: 1, backgroundColor: "#EFF1F3", padding: "24px", width: "100%"}}>
              <div style={{marginBottom: "6px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                {matchData.restaurant.rating === 5 ? (
                  <img src="./images/yelp_rating_small_5.png" alt="5 stars" />
                ) : null}
                {matchData.restaurant.rating === 4.5 ? (
                  <img src="./images/yelp_rating_small_4_half.png" alt="4.5 stars" />
                ) : null}
                {matchData.restaurant.rating === 4 ? (
                  <img src="./images/yelp_rating_small_4.png" alt="4 stars" />
                ) : null}
                {matchData.restaurant.rating === 3.5 ? (
                  <img src="./images/yelp_rating_small_3_half.png" alt="3.5 stars" />
                ) : null}
                {matchData.restaurant.rating === 3 ? (
                  <img src="./images/yelp_rating_small_3.png" alt="3 stars" />
                ) : null}
                {matchData.restaurant.rating === 2.5 ? (
                  <img src="./images/yelp_rating_small_2_half.png" alt="2.5 stars" />
                ) : null}
                {matchData.restaurant.rating === 2 ? (
                  <img src="./images/yelp_rating_small_2.png" alt="2 stars" />
                ) : null}
                {matchData.restaurant.rating === 1.5 ? (
                  <img src="./images/yelp_rating_small_1_half.png" alt="1.5 stars" />
                ) : null}
                {matchData.restaurant.rating === 1 ? (
                  <img src="./images/yelp_rating_small_1.png" alt="1 star" />
                ) : null}
                {matchData.restaurant.rating === 0 ? (
                  <img src="./images/yelp_rating_small_0.png" alt="0 stars" />
                ) : null}

                <div style={{fontFamily: "Heebo", fontWeight: 300, color: "202020", fontSize: "12px", letterSpacing: "0.03em", flexGrow: 1, marginLeft: "12px"}}>
                  {matchData.restaurant.review_count + " review(s)"}
                </div>
              </div>

              <div style={{fontFamily: "Heebo", fontWeight: 900, color: "#202020", fontSize: "18px", letterSpacing: "0.03em"}}>
                {matchData.restaurant.name}
              </div>

              <div style={{fontFamily: "Heebo", fontWeight: 300, color: "#202020", fontSize: "14px", lineHeight: "22px", letterSpacing: "0.03em"}}>
                {matchData.restaurant.location.display_address[0]}
                <br />
                {matchData.restaurant.location.display_address[1]}
              </div>

              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", overflowX: "scroll", marginTop: "12px"}}>
                {matchData.restaurant.categories.map((r, index) => (
                  <div key={index} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingLeft: "12px", paddingRight: "12px", paddingTop: "6px", paddingBottom: "6px", backgroundColor: "#2A324B", height: "34px", borderRadius: "17px", fontFamily: "Heebo", fontWeight: 300, color: "#FFFFFF", fontSize: "12px", letterSpacing: "0.03em", textAlign: "center", marginRight: "12px", flexShrink: 0}}>
                    {r.title}
                  </div>
                ))}
              </div>

              <a href={matchData.restaurant.url} target="_blank" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginTop: "24px"}}>
                <img src="./images/yelp_burst.png" style={{width: "45px", height: "45px", marginRight: "12px"}} alt="Yelp burst logo" />
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
                  <div style={{fontFamily: "Heebo", fontWeight: 900, fontSize: "12px", letterSpacing: "0.03em", lineHeight: "22px", color: "#202020", textAlign: "left"}}>
                    {"Data provided by Yelp"}
                  </div>
                  <div style={{fontFamily: "Heebo", fontWeight: 300, fontSize: "12px", letterSpacing: "0.03em", lineHeight: "22px", color: "#202020", textAlign: "left"}}>
                    {"Read more on Yelp"}
                  </div>
                </div>
              </a>

              {checkIfVoted()}

            </div>
          </div>
        </div>
      )
    }

    if (currentStep === "matched") {
      return (
        <div style={{display: "flex", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
          <div style={{display: "flex", height: "568px", width: "320px", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#2A324B"}}>
            {matchData.restaurant.image_url !== null ? (
              <div style={{width: "100%", height: "200px", overflow: "hidden", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "#202020"}}>
                <img style={{height: "100%", resizeMode: "cover"}} src={matchData.restaurant.image_url} />
              </div>
            ) : (
              null
            )}
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flexGrow: 1, backgroundColor: "#EFF1F3", padding: "24px", width: "100%"}}>

              <div style={{width: "100%", backgroundColor: "#AF125A", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontFamily: "Heebo", fontWeight: 900, color: "#FFFFFF", fontSize: "18px", letterSpacing: "0.03em", textAlign: "center", padding: "12px", borderRadius: "8px", marginBottom: "12px"}}>
                {"You matched!"}
              </div>

              <div style={{marginBottom: "6px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                {matchData.restaurant.rating === 5 ? (
                  <img src="./images/yelp_rating_small_5.png" alt="5 stars" />
                ) : null}
                {matchData.restaurant.rating === 4.5 ? (
                  <img src="./images/yelp_rating_small_4_half.png" alt="4.5 stars" />
                ) : null}
                {matchData.restaurant.rating === 4 ? (
                  <img src="./images/yelp_rating_small_4.png" alt="4 stars" />
                ) : null}
                {matchData.restaurant.rating === 3.5 ? (
                  <img src="./images/yelp_rating_small_3_half.png" alt="3.5 stars" />
                ) : null}
                {matchData.restaurant.rating === 3 ? (
                  <img src="./images/yelp_rating_small_3.png" alt="3 stars" />
                ) : null}
                {matchData.restaurant.rating === 2.5 ? (
                  <img src="./images/yelp_rating_small_2_half.png" alt="2.5 stars" />
                ) : null}
                {matchData.restaurant.rating === 2 ? (
                  <img src="./images/yelp_rating_small_2.png" alt="2 stars" />
                ) : null}
                {matchData.restaurant.rating === 1.5 ? (
                  <img src="./images/yelp_rating_small_1_half.png" alt="1.5 stars" />
                ) : null}
                {matchData.restaurant.rating === 1 ? (
                  <img src="./images/yelp_rating_small_1.png" alt="1 star" />
                ) : null}
                {matchData.restaurant.rating === 0 ? (
                  <img src="./images/yelp_rating_small_0.png" alt="0 stars" />
                ) : null}

                <div style={{fontFamily: "Heebo", fontWeight: 300, color: "202020", fontSize: "12px", letterSpacing: "0.03em", flexGrow: 1, marginLeft: "12px"}}>
                  {matchData.restaurant.review_count} "review(s)"
                </div>
              </div>

              <div style={{fontFamily: "Heebo", fontWeight: 900, color: "#202020", fontSize: "18px", letterSpacing: "0.03em"}}>
                {matchData.restaurant.name}
              </div>

              <div style={{fontFamily: "Heebo", fontWeight: 300, color: "#202020", fontSize: "14px", lineHeight: "22px", letterSpacing: "0.03em"}}>
                {matchData.restaurant.location.display_address[0]}
                <br />
                {matchData.restaurant.location.display_address[1]}
              </div>

              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", overflowX: "scroll", marginTop: "12px"}}>
                {matchData.restaurant.categories.map((r, index) => (
                  <div key={index} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingLeft: "12px", paddingRight: "12px", paddingTop: "6px", paddingBottom: "6px", backgroundColor: "#2A324B", height: "34px", borderRadius: "17px", fontFamily: "Heebo", fontWeight: 300, color: "#FFFFFF", fontSize: "12px", letterSpacing: "0.03em", textAlign: "center", marginRight: "12px", flexShrink: 0}}>
                    {r.title}
                  </div>
                ))}
              </div>

              <a href={matchData.restaurant.url} target="_blank" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginTop: "24px"}}>
                <img src="./images/yelp_burst.png" style={{width: "45px", height: "45px", marginRight: "12px"}} alt="Yelp burst logo" />
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
                  <div style={{fontFamily: "Heebo", fontWeight: 700, fontSize: "12px", letterSpacing: "0.03em", lineHeight: "22px", color: "#202020", textAlign: "left"}}>
                    {"Data provided by Yelp"}
                  </div>
                  <div style={{fontFamily: "Heebo", fontWeight: 300, fontSize: "12px", letterSpacing: "0.03em", lineHeight: "22px", color: "#202020", textAlign: "left"}}>
                    {"Read more on Yelp"}
                  </div>
                </div>
              </a>

              <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "12px"}}>
                <button style={{width: "212px", height: "48px", borderRadius: "24px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", border: "2px solid #FCA311", fontFamily: "Heebo", fontWeight: 700, fontSize: "14px", color: "202020", letterSpacing: "0.03em", textAlign: "center"}} onClick={() => doAgain()}>
                  {"Keep Looking"}
                  <i className="material-icons-round" style={{fontSize: "24px", color: "#202020", marginLeft: "6px"}}>
                    chevron_right
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
};

const ChickenTender = withFirebase(ChickenTenderBase);

export default ChickenTender;
