import React, { useEffect } from 'react';

const PrivacyCatfight = (props) => {

  useEffect(() => {
    document.title = "Mg - Catfight Privacy Policy";
  }, []);

  return (
    <div>
      <div className="full-width-container">
        <div className="max-width-container">
          <img src={"./images/mgCatfightHeader.png"} style={{width: "100%"}} alt="Catfight header"/>
        </div>
      </div>
      <div className="full-width-container" style={{backgroundColor: "#FFFFFF"}}>
        <div className="max-width-container" style={{marginTop: "24px", paddingLeft: "12px", paddingRight: "12px"}}>
          <div className="headline-2">
            {"Privacy Policy for Catfight"}
          </div>
          <div className="content-text" style={{marginBottom: "48px", paddingLeft: "0px", paddingRight: "0px"}}>
            {"Matt Goodman built the Catfight app as an Ad Supported app. This SERVICE is provided by Matt Goodman at no cost and is intended for use as is."}
            <br /><br />
            {"This page is used to inform visitors regarding my policies for the collection, use, and disclosure of Personal Information in relation to the use of the Catfight Service."}
            <br /><br />
            {"I do not access, collect, or store any personally identifiable information. However, this app does use the following third party services that may collect information that could be used to identify you:"}
            <ul>
              <li>
                <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer" className="content-link">Google Play Services</a>
              </li>
              <li>
                <a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer" className="content-link">AdMob</a>
              </li>
              <li>
                <a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer" className="content-link">Firebase Analytics</a>
              </li>
            </ul>
            <br />
            {"Whenever you use my Service, in case of an error in the app, I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (IP) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics."}
            <br /><br />
            {"Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service does not use these 'cookies' explicitly. However, the app may use third party code and libraries that use 'cookies' to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service."}
            <br /><br />
            {"This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services."}
            <br /><br />
            {"These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to perform the necessary actions."}
            <br /><br />
            {"I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page."}
            <br /><br />
            {"If you have any questions or suggestions about my Privacy Policy, please contact me at matt@hellomattgoodman.com"}.
            <br /><br />
            {"This privacy policy page was created at "}
            <a href="https://privacypolicytemplate.net" target="_blank" rel="noopener noreferrer" className="content-link">privacypolicytemplate.net</a> and modified/generated by <a href="https://app-privacy-policy-generator.firebaseapp.com/" target="_blank" rel="noopener noreferrer" className="content-link">App Privacy Policy Generator</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyCatfight;
