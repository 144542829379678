import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'

const About = (props) => {

  useEffect(() => {
    document.title = "Mg - About"
  }, []);

  return (
    <div className="full-width-container" style={{backgroundColor: "#FFFFFF"}}>
      <div className="hero-row-no-reverse" style={{backgroundColor: "#EFF1F3"}}>
        <img src="./images/matt-about.jpg" className="about-hero-image" alt="Matt" />
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#EFF1F3", padding: "48px", flexGrow: 1}}>
          <div className="headline-1" style={{textAlign: "center", maxWidth: "600px"}}>
            {"Experience at scale."}
          </div>
          <div className="subheadline" style={{textAlign: "center", maxWidth: "600px"}}>
            {"Matt has designed and developed digital solutions used by more than two million people across the globe."}
          </div>
        </div>
      </div>
      <div className="feature-bar">

          <div className="feature-bar-short-description">
            <div className="headline-2" style={{color: "#FFFFFF"}}>
              {"At a glance"}
            </div>
            <div className="subheadline" style={{color: "#FFFFFF"}}>
              {"More than a decade of experience, most of it spent at the world's largest retailer."}
            </div>
            <a href="./docs/Resume.pdf" target="_blank" className="primary-button">
              {"View Résumé"}
              <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24}}>
                {"arrow_right_alt"}
              </i>
            </a>
          </div>
          <div className="feature-bar-single-column">
            <div className="feature-bar-tag">
              {"Work Experience"}
            </div>
            <div className="headline-3" style={{color: "#FFFFFF"}}>
              {"Senior UX Designer, Walmart Technology/Walmart Labs"}
            </div>
            <div className="content-text" style={{color: "#FFFFFF", paddingLeft: "0px"}}>
              {"Bentonville, Arkansas"}
              <br />
              {"2008-2019"}
            </div>

            <div className="feature-bar-tag" style={{marginTop: "48px"}}>
              {"Conferences"}
            </div>
            <div className="headline-3" style={{color: "#FFFFFF"}}>
              {"ServiceNow Knowledge 18"}
            </div>
            <div className="headline-3" style={{color: "#B9B9B9"}}>
              {'"Delivering a Modern User Experience in Service Portal"'}
            </div>
            <div className="content-text" style={{color: "#FFFFFF", paddingLeft: "0px"}}>
              {"Las Vegas, Nevada"}
              <br />
              {"May 2018"}
            </div>

            <div className="feature-bar-tag" style={{marginTop: "48px"}}>
              {"Education"}
            </div>
            <div className="headline-3" style={{color: "#FFFFFF"}}>
              {"University of Central Missouri"}
            </div>
            <div className="content-text" style={{color: "#FFFFFF", paddingLeft: "0px"}}>
              {"Bachelor of Science in Business Administration"}
              <br />
              {"2004-2008"}
            </div>
          </div>

      </div>
      <div className="max-width-container" style={{paddingRight: "12px", paddingLeft: "12px"}}>
        <div className="blank-row" />
          <div className="headline-2" style={{textAlign: "center"}}>
            {"A proven record of transforming the way people work."}
          </div>
          <div className="content-text">
            {"Matt started his career at Walmart's Information Systems Division, later "}
            {"rebranded as Walmart Technology and then again as Walmart Labs, located in "}
            {"Bentonville, Arkansas. During his eleven year stint at Walmart, Matt spent time "}
            {"as a Software Engineer supporting and developing systems used by more than 2 million "}
            {"associates across the globe, as a data analyst and report developer tracking the efficiency "}
            {"of Walmart's applications development teams, as a Process Engineer dedicated to making "}
            {"development processes more efficient, and as a User Experience Designer focused on "}
            {"delivering a consumer-grade experience to associates across the company."}
            <br /><br />
            {"Throughout his time at Walmart, Matt was a reliable advocate for change and continuous "}
            {"improvement, constantly seeking out new ways to help his teams--and the organization--"}
            {"be better than they were the day before. Here are a few of the ways Matt helped transform "}
            {"the way people at Walmart worked:"}
            <br /><br />

            <span className="content-text-bold">
            {"As a User Experience Designer"}
            </span>
            {", he designed and implemented the new Service Portal, built on the ServiceNow ITSM platform, "}
            {"in three months. The successful and seamless global rollout saw the Service Portal launched in "}
            {"5+ languages in more than a dozen countries, providing associates across the globe a new way to "}
            {"request IT and HR requests and self-solve with a new knowledge base. Within weeks of its global "}
            {"launch, the Service Portal was regularly averaging 600,000+ page views and 50,000 unique visitors "}
            {"each month. Matt detailed the strategy, key learnings, and journey to a 'sold-out' audience at "}
            {"ServiceNow's Knowledge 18 conference in May 2018. Later, he designed the Open Door portal, a "}
            {"guided system for helping associates at any level submit concerns or ideas, and then led a team of "}
            {"designers working on Faces and Places, later rebranded as Guide, a new internal app for helping "}
            {"associates navigate the expansive Home Office campus."}
            <br /><br />

            <span className="content-text-bold">
            {"As a Software Engineer"}
            </span>
            {" supporting Walmart's new business intelligence platform, Matt took it upon himself to use the "}
            {"platform to design and construct a dashboard that could be used to monitor itself for system "}
            {"health and usage and proactively alert the team of potential impacting issues. He led the "}
            {"redesign of the Central America Merchandising dashboards, which improved that market's visibility "}
            {"into key performance indicators, and designed and developed Tool Central, a new way for developers "}
            {"to stay up-to-date on the toolsets available to help them do their jobs, subscribe to specific "}
            {"tools for updates, and provide feedback to tool owners."}
            <br /><br />

            <span className="content-text-bold">
            {"As a Process Engineer"}
            </span>
            {", he designed and developed a new set of reports, pulling together various data points for the first "}
            {"time, to help Development Managers understand how efficiently they were using their teams. These reports "}
            {"became the basis for how the Applications Development space managed their business, helped executives "}
            {"plan and prioritize work, and eventually became the blueprint for how other areas of the Information "}
            {"Systems Division would manage their own workloads. He improved the efficiency of contract invoice "}
            {"validation by creating a system that could automatically flag differences between the invoice and the "}
            {"hours logged in the system of record, and he worked with Finance Managers to develop a set of financial "}
            {"reports to help Development Managers understand their spend and burn rate."}
            <br /><br />

            {"Matt believes the tech industry is at a crossroads. As the industry continues to invest in algorithms, "}
            {"artificial intelligence, and automation, humanity is being removed from our products. User Experience "}
            {"professionals sit in a crucial spot on our product teams and have the unique ability to push for change "}
            {"that will inject humanity back into our products."}
            <br /><br />

            {"Our purpose should be to do more than deliver delightful experiences and we should go beyond empathizing "}
            {"with our users. Instead, our intent should be to respect our users as humans and to focus on doinog the "}
            {"most good we can for our users, for our stakeholders, and for the world."}
          </div>
        <div className="blank-row" />
      </div>
      <div className="call-to-action-bar">
        <div className="max-width-container">
          <div className="headline-2" style={{color: "#FFFFFF", textAlign: "center"}}>
            {"Happy to help."}
          </div>
          <div className="content-text" style={{color: "#FFFFFF"}}>
            {"As technologists, we have the ability to deliver digital solutions that respect our users as humans, "}
            {"transforming the way they work, learn, play, interact, and live their lives. If you need help "}
            {"establishing or executing your digital strategy, from digital product design to reimagining processes, "}
            {"from software engineering to thought leadership, I'm always happy to help."}
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <a href="mailto:matt@hellomattgoodman.com" className="primary-button">
              {"say hello"}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
