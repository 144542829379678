import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const TutorialOverview = (props) => {
  const [thisTutorial, setThisTutorial] = useState(null);
  const { tutorialSlug } = useParams();
  //const thisTutorial = tutorials.tutorials[0];

  useEffect(() => {
    loadTutorial();
  }, []);

  const loadTutorial = async () => {
    const tutorials = await import('../../constants/' + tutorialSlug + '/' + tutorialSlug + '.js');
    setThisTutorial(tutorials.TUTORIAL.projects[0]);
  }

  if (!thisTutorial) {
    return null;
  }

  return (
    <div>
      <div className="full-width-container">
        <div className="hero-row-no-reverse" style={{background: 'url(' + thisTutorial.hero_bg + ')', backgroundSize: 'cover', paddingTop: '96px', paddingBottom: "0px"}}>
          <div className="max-width-container">
            <div className="tutorial-hero-row">
              <div className="tutorial-hero-column">
                <span className="tutorial-title">
                  {thisTutorial.project_designator}
                </span>
                <br />
                <span className="tutorial-subtitle">
                  {thisTutorial.project_title}
                </span>
              </div>
              <img src={thisTutorial.hero} className="feature-phone-image" alt="A screenshot of the Simple Calculator app running on a smartphone device"/>
            </div>
          </div>
        </div>
      </div>

      <div className="full-width-container" style={{backgroundColor: "#FFFFFF"}}>
        <div className="max-width-container" style={{marginTop: "48px", paddingLeft: "12px", paddingRight: "12px"}}>
          <Link to="/developing-together" className="flat-button" style={{marginBottom: "48px"}}>
            <i className="material-icons-round" style={{color: "#009FB7", fontSize: "24px", marginRight: "6px"}}>arrow_back</i>
            Back to tutorial projects
          </Link>
          <div className="headline-2" style={{textAlign: "left"}}>
            {thisTutorial.overview.headline}
          </div>
          <div className="content-text" style={{padding: "0px"}}>
            <div dangerouslySetInnerHTML={{ __html: thisTutorial.overview.overview_text}} />
          </div>

          <div className="headline-3" style={{color: '#202020', marginTop: '48px'}}>
            Project objectives
          </div>
          {thisTutorial.objectives.map((objective, index) => (
            <div key={"objective" + index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: 12, marginBottom: 12}}>
              <div style={{marginRight: "12px"}}>
                <i className="material-icons-round" style={{color: thisTutorial.accent_color, fontSize: 24}}>
                  {objective.icon}
                </i>
              </div>
              <div className="content-text" style={{padding: "0px"}}>
                {objective.objective_text}
              </div>
            </div>
          ))}

          <div className="headline-3" style={{color: '#202020', marginTop: '48px'}}>
            Lesson plan
          </div>
          {thisTutorial.lesson_plan.map((lesson, index) => (
            <Link key={"lesson" + index} to={'/developing-together/' + tutorialSlug + '/' + lesson.lesson_slug} style={{width: '100%'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 12, marginBottom: 12, width: '100%'}}>
                <div className="content-text" style={{marginRight: "12px", color: thisTutorial.accent_color, fontWeight: 900, padding: "0px"}}>
                  {lesson.number}
                </div>
                <div className="content-text" style={{flexGrow: 1, padding: "0px"}}>
                  {lesson.lesson_title}
                </div>
                <div style={{marginLeft: "12px"}}>
                  <i className="material-icons-round" style={{color: "#009FB7", fontSize: 24}}>
                    chevron_right
                  </i>
                </div>
              </div>
            </Link>
          ))}

          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <Link to={"/developing-together/" + tutorialSlug + '/' + thisTutorial.lesson_plan[0].lesson_slug} className="primary-button" style={{marginTop: "60px", marginBottom: "94px", width: "200px", alignSelf: "center"}}>
              Get Started
              <i className="material-icons-round" style={{color: "#2A324B", fontSize: 24, marginLeft: "6px"}}>
                arrow_right_alt
              </i>
            </Link>
          </div>

          <div style={{borderRadius: "8px", backgroundColor: "#EFD28D", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", padding: "24px", marginBottom: "94px"}}>
            <a href={thisTutorial.github_link} target="_blank" rel="noopener noreferrer" style={{color: "#2A324B", fontFamily: "Heebo", fontSize: "18px", fontWeight: 900}}>
              View the completed code on GitHub
            </a>
            <i className="material-icons-round" style={{color: "#AF125A", marginLeft: "6px"}}>
              launch
            </i>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TutorialOverview;
