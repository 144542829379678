import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';

import ErrorBoundary from './ErrorBoundary';


/*HOME SCREEN, CREATE OR JOIN GAME, ENTER NAME*/
const GreetingBase = props => {
  //set up state variables
  const [currentStep, setCurrentStep] = useState('intro');
  const [gameCode, setGameCode] = useState('');
  const [gameDocID, setGameDocID] = useState('');
  const [userName, setUserName] = useState('');
  const [error, setError] = useState('');

  //store the game code entered by the user
  const updateGameCode = e => {
    if (e.target.value.length <= 4) {
      setGameCode(e.target.value.toUpperCase());
    }
  }

  //create a new game and then ask the user for their name
  const createGame = () => {
    props.firebase.generateGameCode()
    .then(r => {
      if (!r.hasError) {
        setGameDocID(r);
        setCurrentStep('get-name');
      }
    });
  }

  //store the name entered by the user
  const updateName = () => {
    if (userName.length > 0) {
      props.firebase.updateUserDisplayName(userName)
      .then(r => {
        if (!r.hasError) {
          props.joinGame(gameDocID);
        } else {
          let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
          setError(() => { throw friendlyError });
        }
      });
    }
  }

  //join a game with a given game code
  const joinGame = () => {
    if (gameCode.length === 4) {
      props.firebase.joinGame(gameCode)
      .then(r => {
        if (!r.hasError) {
          setGameDocID(r.value);
          setCurrentStep('get-name');
        } else {
          if (r.value === 'game-not-found') {
            setCurrentStep('error-game-not-found');
          } else {
            let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
            setError(() => { throw friendlyError });
          }
        }
      });
    }
  }

  return (
    <div className="ao-game-container">
      <div className="ao-game-inner-container">
        <div className="ao-greetings-image">
          <img src="./images/ask-ouija-greetings-background.png" style={{width: '100%'}} alt="A dancing skeleton" />
        </div>
        <div className="ao-game-bar">

          {currentStep === 'intro' ? (
            <>
              <div className="ao-game-title">
                {"Ask Ouija"}
              </div>
              <div className="ao-headline">
                {"Greetings, mortal"}
              </div>
              <div className="ao-text">
                {"Join hands with your friends and ask the Spirits to answer your most pressing questions."}
              </div>
              <div className="ao-primary-button" onClick={() => setCurrentStep('create-or-join')}>
                <div className="ao-primary-button-text">
                  {"Get Started"}
                </div>
              </div>
            </>
          ) : (
            null
          )}

          {currentStep === 'create-or-join' ? (
            <>
              <div className="ao-primary-button" onClick={() => createGame()}>
                <div className="ao-primary-button-text">
                  {"Create a Group"}
                </div>
              </div>
              <div className="ao-or-divider-row">
                <div className="ao-or-divider" />
                <div className="ao-or-divider-text">
                  {"or"}
                </div>
                <div className="ao-or-divider" />
              </div>
              <div className="ao-text" style={{textAlign: "left"}}>
                {"Enter a code to join a group:"}
              </div>
              <div className="ao-enter-code-row">
                <input
                className="ao-code-textbox"
                placeholder="????"
                value={gameCode}
                onChange={e => updateGameCode(e)} />
                <div className={gameCode.length === 4 ? "ao-primary-button" : "ao-primary-button-disabled"} style={{marginTop: "0px", marginLeft: "6px"}} onClick={() => joinGame()}>
                  <div className="ao-primary-button-text">
                    {"Find Group"}
                  </div>
                </div>
              </div>
            </>
          ) : (
            null
          )}

          {currentStep === 'get-name' ? (
            <>
              <div className="ao-headline">
                {"Who dares disturb the slumber of the Spirits?"}
              </div>
              <div className="ao-enter-code-row">
                <input
                className="ao-textbox"
                placeholder="Give us your name..."
                value={userName}
                onChange={e => setUserName(e.target.value)} />
                <div className={userName.length > 0 ? "ao-primary-button" : "ao-primary-button-disabled"} style={{marginTop: "0px", marginLeft: "6px"}} onClick={() => updateName()}>
                  <div className="ao-primary-button-text">
                    {"Join"}
                  </div>
                </div>
              </div>
            </>
          ) : (
            null
          )}

          {currentStep === 'error-game-not-found' ? (
            <>
              <div className="ao-text">
                {"The Spirits could not connect you with your friends. Have you provided the Spirits with the correct Group Code?"}
              </div>
              <div className="ao-primary-button" onClick={() => setCurrentStep('create-or-join')}>
                <div className="ao-primary-button-text">
                  {"Go Back"}
                </div>
              </div>
            </>
          ) : (
            null
          )}

        </div>
      </div>
    </div>
  );
};


/* SHOW LIST OF PLAYERS WHILE WE WAIT FOR MORE PEOPLE TO JOIN*/
const LobbyBase = props => {
  const [error, setError] = useState('');

  //if I'm the game owner and enough people have joined, let me start the game
  const startGame = () => {
    if ((props.GameData.owner === props.firebase.auth.currentUser.uid) && (props.GameData.players.length > 2)) {
      props.firebase.startGame(props.GameID)
      .then(r => {
        if (r.hasError) {
          let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
          setError(() => { throw friendlyError });
        }
      });
    }
  }

  if (props.GameData === undefined) {
    props.changeScreen('greeting');
    return null;
  }

  return (
    <div className="ao-game-container">
      <div className="ao-game-inner-container">
        <div className="ao-lobby-container">
          <div className="ao-lobby-inner-container">
            <div className="ao-text">
              {"Your friends can join your group using this code:"}
            </div>
            <div className="ao-game-code">
              {props.GameData.gameCode}
            </div>
            <>
              {props.GameData.players.map((player, index) => (
                <div key={index} className="ao-player-row">
                  <div className="ao-player-row-avatar">
                    <img src={"data:image/png;base64, " + player.avatar} alt="player avatar" style={{width: "36px", height: "36px"}}/>
                  </div>
                  <div className="ao-player-row-name">
                    {player.displayName}
                  </div>
                </div>
              ))}
            </>
          </div>
          <div className="ao-text" style={{marginTop: "12px", marginBottom: "24px"}}>
            {props.GameData.players.length < 8 ? "Waiting for people to join..." : null}
          </div>
          {(props.GameData.owner === props.firebase.auth.currentUser.uid && props.GameData.players.length > 2) ? (
            <div className="ao-primary-button" onClick={() => startGame()}>
              <div className="ao-primary-button-text">
                {"Let's Play"}
              </div>
            </div>
          ) : (
            null
          )}
        </div>
      </div>
    </div>
  );
};



/* DISPLAY A LIST OF QUESTIONS FOR THE PLAYER TO CHOOSE FROM */
const QuestionAskerBase = props => {
  //set up state variables
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({ id: -1, text: '' });
  const [customQuestionText, setCustomQuestionText] = useState('');
  const [error, setError] = useState('');

  //save the player's question choice
  const selectQuestion = (index, question) => {
    setSelectedQuestion({ id: index, text: question });
  }

  //update the database with the player's selected question
  const askQuestion = () => {
    let questionText = '';
    if (customQuestionText.length > 0) {
      questionText = customQuestionText;
    } else if (selectedQuestion.text.length > 0) {
      questionText = selectedQuestion.text;
    }

    if (questionText.length > 0) {
      props.firebase.updateQuestion(props.GameID, questionText)
      .then(r => {
        if (r.hasError) {
          let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
          setError(() => { throw friendlyError });
        }
      });
    }
  }

  //update customQuestionText if the player writes a custom question
  const doCustomQuestion = text => {
    setCustomQuestionText(text);
    if (selectedQuestion.id !== -1) {
      setSelectedQuestion({ id: -1, text: '' });
    }
  }

  //on component load, get the list of questions
  useEffect(() => {
    //get a list of 3 randomly selected questions
    if ((props.GameData.questionAsker.uid === props.firebase.auth.currentUser.uid) && (props.GameData.question === "")) {
      setSelectedQuestion({ id: -1, text: '' });
      setCustomQuestionText('');
      const getQuestions = async() => {
        let someQuestions = [];
        while (someQuestions.length < 3) {
          let alreadyPicked = false;
          let potentialQuestion = await props.firebase.getQuestion();
          someQuestions.forEach(question => {
            if (question === potentialQuestion) {
              alreadyPicked = true;
            }
          });
          if (!alreadyPicked) {
            someQuestions.push(potentialQuestion);
          }
        }
        setQuestions(someQuestions);
      }
      getQuestions();
    }
  }, [props.firebase, props.GameData]);

  //show the question list if no question has been asked
  if ((props.GameData !== undefined) && (props.GameData.question === '')) {
    return (
      <div className="ao-game-container">
        <div className="ao-game-inner-container">
          <div className="ao-lobby-container">
            <div className="ao-lobby-inner-container">
              <div className="ao-headline">
                {"Mortal, which query shall the Spirits answer?"}
              </div>
              <div style={{display: 'flex', flexDirection: 'column', marginTop: "36px", alignItems: "center", justifyContent: "flex-start", width: "100%"}}>
                {questions.map((question, index) => (
                  <div className="ao-question-row" key={index} onClick={() => selectQuestion(index, question)} style={{border: selectedQuestion.id === index ? "1px solid #ECA72C" : "none"}}>
                    <div className="ao-question-text">
                      {question}
                    </div>
                  </div>
                ))}
                <input className="ao-textbox" style={{marginLeft: "12px", marginRight: "12px"}} onChange={(e) => doCustomQuestion(e.target.value)} value={customQuestionText} placeholder="Ask your own question..."/>
              </div>
            </div>
            <div className={((selectedQuestion.id !== -1) || (customQuestionText.length > 0)) ? "ao-primary-button" : "ao-primary-button-disabled"} onClick={() => askQuestion()}>
              <div className="ao-primary-button-text">
                {"Ask Your Question"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //once the question has been asked, show the spirits answering the question
  if ((props.GameData !== undefined) && (props.GameData.question !== '')) {
    return (
      <ParticipatingSpirit GameID={props.GameID} GameData={props.GameData} />
    )
  }

  //if all else fails, show nothing
  return null;
};



/* LET PLAYERS CHOOSE A LETTER TO ADD TO THE ANSWER */
const AnsweringSpiritBase = props => {
  //establish state and other variables
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const [selectedLetter, setSelectedLetter] = useState('');
  const [error, setError] = useState('');

  //add the player's selected letter to the answer. Then, find a new spirit to provide the next letter.
  //the new spirit cannot be the same person as the question asker or the current answering spirit.
  const addLetter = () => {
    props.firebase.updateAnswer(props.GameID, props.GameData.answer + selectedLetter)
    .then(r => {
      if (!r.hasError) {
        //find the next answeringSpirit
        let newAnsweringSpirit = props.GameData.players[Math.floor(Math.random() * ((props.GameData.players.length - 1) - 0 + 1) + 0)];
        while ((newAnsweringSpirit.uid === props.GameData.answeringSpirit.uid) || (newAnsweringSpirit.uid === props.GameData.questionAsker.uid)) {
          newAnsweringSpirit = props.GameData.players[Math.floor(Math.random() * ((props.GameData.players.length - 1) - 0 + 1) + 0)];
        }
        updateAnsweringSpirit(newAnsweringSpirit);
      } else {
        let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
        setError(() => { throw friendlyError });
      }
    });
  }

  //select the letter if it's different from the already selected letter. If it's the same, deselect the letter
  const toggleLetter = letter => {
    if (letter === selectedLetter) {
      setSelectedLetter('')
    } else {
      setSelectedLetter(letter);
    }
  }

  //the spirit has decided the question has been answered. Choose a new question asker
  //and a new answering spirit
  const finalizeAnswer = () => {
    props.firebase.finalizeAnswer(props.GameID, props.GameData)
    .then(r => {
      if (!r.hasError) {
        //start the next game
        props.firebase.startGame(props.GameID)
        .then(rr => {
          if (rr.hasError) {
            let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
            setError(() => { throw friendlyError });
          }
        });
      } else {
        let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
        setError(() => { throw friendlyError });
      }
    });
  }

  //once a new answering spirit has been selected, update the database
  const updateAnsweringSpirit = newAnsweringSpirit => {
    props.firebase.updateAnsweringSpirit(props.GameID, newAnsweringSpirit)
    .then(r => {
      if (r.hasError) {
        let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
        setError(() => { throw friendlyError });
      }
    });
  }

  //render a waiting screen for the answering spirit while the question asker selects their question
  if ((props.GameData !== undefined) && (props.GameData.status === "playing") && (props.GameData.question === "")) {
    return (
      <WaitingForAction GameID={props.GameID} GameData={props.GameData} />
    );
  }

  //display the answer space for the answering spirit
  if ((props.GameData !== undefined) && (props.GameData.status === 'playing') && (props.GameData.question !== "")) {
    return (
      <div className="ao-game-container">
        <div className="ao-game-inner-container" style={{justifyContent: "center"}}>
          <div className="ao-lobby-container">
            <div className="ao-lobby-inner-container">
              <div className="ao-headline">
                {"O Spirit, answer my query, please. "}
                <span style={{color: "#ECA72C"}}>
                  {props.GameData.question}
                </span>
              </div>
              <div className="ao-answer-space">
                {[...props.GameData.answer].map((letter, index) => (
                  <div key={index} className="ao-answer-letter-tile">
                    <div className="ao-answer-letter-tile-text">
                      {letter}
                    </div>
                  </div>
                ))}
                <div className="ao-answer-letter-tile">
                  <div className="ao-answer-letter-tile-text">
                    {"_"}
                  </div>
                </div>
              </div>
            </div>
            <div className="ao-spirit-watching-game-bar" style={{backgroundColor: "#000000"}}>
              <div className="ao-text" style={{alignSelf: "flex-start"}}>
                {"Add a letter to the answer (swipe to see more letters):"}
              </div>
              <div className="ao-letter-bar">
                {letters.map(letter => (
                  <div key={letter} className={selectedLetter === letter ? "ao-answer-letter-tile-selected" : "ao-answer-letter-tile"} style={{marginTop: "0px", marginBottom: "0px", cursor: "pointer"}} onClick={() => toggleLetter(letter)}>
                    <div className={selectedLetter === letter ? "ao-answer-letter-tile-selected-text" : "ao-answer-letter-tile-text"}>
                      {letter}
                    </div>
                  </div>
                ))}
              </div>
              <div className={selectedLetter !== "" ? "ao-primary-button" : "ao-primary-button-disabled"} onClick={() => addLetter()}>
                <div className="ao-primary-button-text">
                  {"Add Letter"}
                </div>
              </div>
              <div className={selectedLetter === "" ? "ao-secondary-button" : "ao-secondary-button-disabled"} onClick={() => finalizeAnswer()}>
                <div className={selectedLetter === "" ? "ao-secondary-button-text" : "ao-secondary-button-disabled-text"}>
                  {"Finalize Answer"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //if all else fails, just display a blank screen
  return null;
};



/* DISPLAY THE Q AND A FOR PLAYERS WHO ARE NOT THE ASKER OR THE ANSWERING SPIRIT */
const ParticipatingSpiritBase = props => {
  //if the question asker has asked their question, show the game board
  if ((props.GameData !== undefined) && (props.GameData.status === "playing") && (props.GameData.question !== "")) {
    return (
      <div className="ao-game-container">
        <div className="ao-game-inner-container" style={{justifyContent: "center"}}>
          <div className="ao-lobby-container">
            <div className="ao-lobby-inner-container">
              <div className="ao-headline">
                {"O Spirit, answer my query, please. "}
                <span style={{color: "#ECA72C"}}>
                  {props.GameData.question}
                </span>
              </div>
              <div className="ao-answer-space">
                {[...props.GameData.answer].map((letter, index) => (
                  <div key={index} className="ao-answer-letter-tile">
                    <div className="ao-answer-letter-tile-text">
                      {letter}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="ao-spirit-watching-game-bar" style={{backgroundImage: `url("./images/ask-ouija-status-background.png")`, backgroundSize: "cover"}}>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}>
                <div className="ao-player-row-avatar" style={{marginRight: "0px"}}>
                  <img src={"data:image/png;base64, " + props.GameData.answeringSpirit.avatar} alt="player avatar" style={{width: "36px", height: "36px"}} />
                </div>
                <div className="ao-spirit-watching-game-bar-text">
                  <span style={{color: "#ECA72C"}}>
                    {props.GameData.answeringSpirit.displayName}
                  </span>
                  {" is answering the query"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //if the question asker has not asked their question, show a waiting screen
  if ((props.GameData !== undefined) && (props.GameData.status === "playing") && (props.GameData.question === "")) {
    return (
      <WaitingForAction GameID={props.GameID} GameData={props.GameData} />
    )
  }

  //if all else fails, show nothing
  return null;
};


/* DISPLAY A WAITING SCREEN WHILE THE Q ASKER SELECTS THEIR Q */
const WaitingForActionBase = props => {
  return (
    <div className="ao-game-container">
      <div className="ao-game-inner-container" style={{justifyContent: "center"}}>
        <div className="ao-lobby-container">
          <div className="ao-lobby-inner-container" style={{backgroundImage: `url("./images/ask-ouija-waiting-for-question.png")`, backgroundSize: "cover"}} />
          <div className="ao-spirit-watching-game-bar">
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}>
              <div className="ao-player-row-avatar" style={{marginRight: "0px"}}>
                <img src={"data:image/png;base64, " + props.GameData.questionAsker.avatar} alt="player avatar" style={{width: "36px", height: "36px"}} />
              </div>
              <div className="ao-spirit-watching-game-bar-text">
                <span style={{color: "#ECA72C"}}>
                  {props.GameData.questionAsker.displayName}
                </span>
                {" is asking a question"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


/* MAIN GAME CONTROL */
const AskOuijaBase = props => {
  //declare state variables
  const [auth, setAuth] = useState(null);
  const [currentScreen, setCurrentScreen] = useState('greeting');
  const [gameData, setGameData] = useState({});
  const [gameID, setGameID] = useState();
  const [error, setError] = useState('');

  var subscriber = null;

  //change the current screen to a new screen
  const changeScreen = newScreen => {
    setCurrentScreen(newScreen);
  };

  //update the stored game data when the db game data changes
  const updateGameData = newGameData => {
    setGameData(newGameData);
  }

  //subscribe to db changes for a game
  const joinGame = gameDocID => {
    setGameID(gameDocID);
    //start listening for updates to game data
    subscriber = props.firebase.firestore.collection("ao-games").doc(gameDocID).onSnapshot(doc => {
      updateGameData(doc.data());
    });

    //add me to the current players list
    props.firebase.getAvatar()
    .then(a => {
      if (!a.hasError) {
        props.firebase.getPlayers(gameDocID)
        .then(r => {
          if (!r.hasError) {
            let players = r;
            let alreadyThere = false;
            players.forEach(player => {
              if (player.uid === props.firebase.auth.currentUser.uid) {
                alreadyThere = true;
              }
            });
            if (!alreadyThere) {
              players.push({
                uid: props.firebase.auth.currentUser.uid,
                displayName: props.firebase.auth.currentUser.displayName,
                avatar: a,
              });
              props.firebase.updatePlayers(gameDocID, players)
              .then(rr => {
                if (!rr.hasError) {
                  setCurrentScreen('lobby');
                } else {
                  let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
                  setError(() => { throw friendlyError });
                }
              });
            } else {
              setCurrentScreen('lobby');
            }
          } else {
            let friendlyError = { friendly: "Something has gone terribly wrong.", technical: r.value !== undefined ? r.value.toString() : '' };
            setError(() => { throw friendlyError });
          }
        });
      }
    });
  };

  //destroy the db subscription when the component unmounts
  useEffect(() => {
    return (() => {
      if (subscriber !== null) {
        subscriber();
      }
    });
  }, []);

  //authorize the user to read and write to the db
  useEffect(() => {
    props.firebase.doSignIn()
    .then(authUser => {
      setAuth(authUser);
    })
    .catch(err => {
      console.error(err);
    });
  }, [props]);

  if ((gameData !== undefined) && (gameData.status === "playing") && (gameData.questionAsker.uid === props.firebase.auth.currentUser.uid)) {
    return <ErrorBoundary><QuestionAsker GameData={gameData} GameID={gameID} /></ErrorBoundary>
  }

  if ((gameData !== undefined) && (gameData.status === "playing") && (gameData.answeringSpirit.uid === props.firebase.auth.currentUser.uid)) {
    return <ErrorBoundary><AnsweringSpirit GameData={gameData} GameID={gameID} /></ErrorBoundary>
  }

  if ((gameData !== undefined) && (gameData.status === "playing")) {
    return <ErrorBoundary><ParticipatingSpirit GameData={gameData} GameID={gameID} /></ErrorBoundary>
  }

  if ((currentScreen === 'greeting') && (auth)) {
    return <ErrorBoundary><Greeting CurrentScreen={currentScreen} changeScreen={changeScreen} joinGame={joinGame} /></ErrorBoundary>;
  }

  if ((currentScreen === 'lobby') && (auth)) {
    return <ErrorBoundary><Lobby GameData={gameData} GameID={gameID} changeScreen={changeScreen} /></ErrorBoundary>
  }

  return null;
};

const AskOuija = withFirebase(AskOuijaBase);
const Greeting = withFirebase(GreetingBase);
const Lobby = withFirebase(LobbyBase);
const QuestionAsker = withFirebase(QuestionAskerBase);
const AnsweringSpirit = withFirebase(AnsweringSpiritBase);
const ParticipatingSpirit = withFirebase(ParticipatingSpiritBase);
const WaitingForAction = withFirebase(WaitingForActionBase);

export default AskOuija;
export { Greeting, Lobby, QuestionAsker, AnsweringSpirit, ParticipatingSpirit, WaitingForAction };
