import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const Article = (props) => {
  const [thisArticle, setThisArticle] = useState(null);
  var { articleSlug } = useParams();

  useEffect(() => {
    loadArticle();
  }, [articleSlug]);

  const loadArticle = async () => {
    const articles = await import('../../constants/articles/' + articleSlug + '.js');
    setThisArticle(articles.ARTICLES.article[0]);
    document.title = "Mg - " + articles.ARTICLES.article[0].title;
  }

  return (
    <div>
      <div className="full-width-container">
        <div className="hero-row" style={{padding: "48px"}}>
          { thisArticle ? <img src={thisArticle.hero} style={{width: "100%", maxWidth: "880px"}} alt="Article header"/> : null}
        </div>
      </div>
      <div className="full-width-container" style={{backgroundColor: "#FFFFFF"}}>
        <div className="max-width-container" style={{marginTop: "48px", paddingLeft: "12px", paddingRight: "12px"}}>
          <Link to="/articles" className="flat-button" style={{marginBottom: "24px"}}>
            <i className="material-icons-round" style={{color: "#009FB7", fontSize: "24px", marginRight: "6px"}}>arrow_back</i>
            Back to articles
          </Link>
          <div className="headline-2" style={{textAlign: "left"}}>
            {thisArticle ? thisArticle.title : null}
          </div>
          <div className="content-text" style={{paddingLeft: "0px", marginBottom: "48px"}}>
            <div dangerouslySetInnerHTML={{ __html: thisArticle ? thisArticle.sections[0].text : null }} />
            {thisArticle ? (thisArticle.sections.map((section, index) => (
              index > 0 ? (
                <div key={index}>
                  <div className="headline-3">{section.title} </div>
                  <div dangerouslySetInnerHTML={{ __html: section.text }} />
                </div>
              ) : null
            ))) : null}
          </div>
          <Link to="/articles" className="flat-button" style={{marginTop: "24px", marginBottom: "96px"}}>
            <i className="material-icons-round" style={{color: "#009FB7", fontSize: "24px", marginRight: "6px"}}>arrow_back</i>
            Back to articles
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Article;
