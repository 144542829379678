import React from 'react';
import { Link } from 'react-router-dom'

const MobileDevelopmentCourse = (props) => {
  return (
    <div>
      <div className="full-width-container" style={{paddingBottom: "0px"}}>
        <div className="max-width-container" style={{backgroundColor: "#EFF1F3", padding: "48px"}}>
          <div className="hero-row-no-reverse" style={{backgroundColor: "#EFF1F3"}}>
            <img src="../images/Catfight-ProductPage-FeatureImage.png" className="about-image" style={{maxWidth: "300px"}} alt="Catfight Logo" />
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#EFF1F3", padding: "48px", flexGrow: 1}}>
              <div className="headline-1" style={{textAlign: "center", maxWidth: "880px"}}>
                {"Share their stories. Save their lives"}
              </div>
            </div>
          </div>
        </div>
        <div className="feature-bar">
          <div className="feature-bar-short-description">
            <a href="https://apps.apple.com/us/app/catfight/id1502853247?ls=1">
              <img src="../images/Catfight-AppStore.png" style={{width: "168px"}} alt="Download on the App Store" />
            </a>
            <br />
            <a href="https://play.google.com/store/apps/details?id=com.mg.catfight">
              <img src="../images/Catfight-GooglePlay.png" style={{width: "168px"}} alt="Get it on Google Play" />
            </a>
          </div>
          <div className="feature-bar-single-column">
            <div className="content-text" style={{color: "#FFFFFF"}}>
              {"Nearly half of all cat owners in the United States first heard about their furry friend through word-of-mouth. Catfight makes the process of discovering adoptable kitties and sharing their stories fun and easy by asking you to make an impossible decision: which adoptable cat is cuter? With new matches every three minutes, you'll discover an endless stream of cute cuddlers, sweet seniors, and playful pals, all currently looking for homes. Built-in sharing functionality makes it easy for you to share your favorites with friends, family, and followers, helping build potentially life-saving word-of-mouth."}
            </div>
            <br /><br />
            <div className="headline-2" style={{color: "#FFFFFF", fontSize: "18px"}}>
              {"Learn how Catfight was made"}
            </div>
            <div className="content-text" style={{color: "#FFFFFF"}}>
              {"If you're interested in how apps are designed and developed, click the button below to learn more about the process behind how Catfight came to be."}
            </div>
            <Link to="/portfolio/catfight" className="primary-button" style={{marginTop: "12px"}}>
              {"Learn more"}
              <i className="material-icons-round" style={{marginLeft: "6px"}}>
                arrow_right_alt
              </i>
            </Link>
          </div>
        </div>
      </div>

      <div className="full-width-container" style={{backgroundColor: "#FFFFFF", marginTop: "400px"}}>
        <div className="max-width-inner-container" style={{background: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODMyIiBoZWlnaHQ9IjQ2NiIgdmlld0JveD0iMCAwIDgzMiA0NjYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNODMyIDE4NS40NDNMMCAwLjYzNjcxOVY0NjUuMTRMODMyIDQyNS41MDVWMTg1LjQ0M1oiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcikiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhciIgeDE9IjE3MjEuOTEiIHkxPSIzODMuMTExIiB4Mj0iLTI5NzciIHkyPSIzODMuMTExIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiNFM0I1MDUiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjREI1MDRBIi8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==')", backgroundRepeat: "no-repeat", backgroundSize: "contain", flexDirection: "column", justifyContent: "center"}}>
          <img src="../images/Catfight-ImpossibleDecisions.png" className="phone-image" alt="The Catfight home screen, showing the currently active battle" style={{marginTop: "-100px"}}/>
        </div>
        <div className="max-width-container" style={{marginTop: "24px", paddingLeft: "12px", paddingRight: "12px"}}>
          <div className="headline-2">
            {"Impossible decisions abound"}
          </div>
          <div className="content-text" style={{padding: "0px"}}>
            {"Every three minutes, you'll be asked to make an impossible decision: which adoptable cat is cuter? The winner moves on and faces off against a new opponent while the loser hopes to fight another day. Longer win streaks mean more battles, which means more opportunity for someone to discover their new best friend."}
          </div>
        </div>
      </div>

      <div className="full-width-container" style={{backgroundColor: "#FFFFFF", marginTop: "250px"}}>
        <div className="max-width-inner-container" style={{background: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODMyIiBoZWlnaHQ9IjgwMiIgdmlld0JveD0iMCAwIDgzMiA4MDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNODMyIDAuMTk1MzEyTDAgMzY5Ljg5NVY4MDEuMjMyTDgzMiA2NzMuODFWMC4xOTUzMTJaIiBmaWxsPSJ1cmwoI3BhaW50cGlua19saW5lYXIpIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50cGlua19saW5lYXIiIHgxPSItNDM2IiB5MT0iNzQ3LjUiIHgyPSIxMTI0LjM2IiB5Mj0iNzUuMzY5OSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRkY0RDgwIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0RGMzY3QyIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=')", backgroundRepeat: "no-repeat", backgroundSize: "contain", flexDirection: "column", justifyContent: "center"}}>
          <img src="../images/Catfight-FindLove.png" className="phone-image" alt="A screenshot of Catfight showing a cat's profile" style={{marginTop: "50px"}}/>
        </div>
        <div className="max-width-container" style={{marginTop: "24px", paddingLeft: "12px", paddingRight: "12px"}}>
          <div className="headline-2">
            {"Find love without swiping right"}
          </div>
          <div className="content-text" style={{padding: "0px"}}>
            {"Millions of cats across the country are currently looking for homes. As you vote for your favorites in the hundreds of live battles each day, you can read their bios, view their pictures, and use the Learn More button to be taken directly to their Petfinder page. Ready to commit? For many kitties, you can kick off the adoption process directly from Petfinder."}
            <br /><br />
            {"Not looking for yourself? Use the Share button to let your friends, family, and followers know you've found a furry friend you think they'll like."}
          </div>
        </div>
      </div>

      <div className="full-width-container" style={{backgroundColor: "#FFFFFF", marginTop: "375px"}}>
        <div className="max-width-inner-container" style={{background: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODMyIiBoZWlnaHQ9IjYzOSIgdmlld0JveD0iMCAwIDgzMiA2MzkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNODMyIDAuNjIxMDk0TDAgMTAyLjY5NlY1MTcuNTYxTDgzMiA2MzguMjlWMC42MjEwOTRaIiBmaWxsPSJ1cmwoI3BhaW50Z3JlZW5fbGluZWFyKSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludGdyZWVuX2xpbmVhciIgeDE9Ii00NjcuNTgzIiB5MT0iMzI5LjM0OCIgeDI9IjEyMjAuOTkiIHkyPSIxNDEuNzIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzdEQ0ZCNiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMzM0NBN0YiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K')", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundSize: "contain", flexDirection: "column", justifyContent: "center"}}>
          <img src="../images/Catfight-Stats.png" className="phone-image" alt="A screenshot of Catfight showing cats who have received the most votes" style={{marginTop: "-75px"}}/>
        </div>
        <div className="max-width-container" style={{marginTop: "24px", paddingLeft: "12px", paddingRight: "12px"}}>
          <div className="headline-2">
            {"Cool cats for days"}
          </div>
          <div className="content-text" style={{padding: "0px"}}>
            {"These fan-favorite felines have won the hearts and minds of Catfight users across the country. Use the Stats screen to keep track of the cats who have received the most votes, won the most battles, and who have been shared the most."}
          </div>
        </div>
      </div>

      <div className="full-width-container" style={{backgroundColor: "#FFFFFF", marginTop: "220px"}}>
        <div className="max-width-inner-container" style={{background: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODMyIiBoZWlnaHQ9Ijg1MyIgdmlld0JveD0iMCAwIDgzMiA4NTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNODMxLjk5NCA4NTIuMzY2TDAgNzE0LjYwOFYwLjQxNzk2OUw4MzEuOTk0IDMzOS41MTJWODUyLjM2NloiIGZpbGw9InVybCgjcGFpbnRibHVlX2xpbmVhcikiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnRibHVlX2xpbmVhciIgeDE9IjE3MTMiIHkxPSI4MjcuNzg2IiB4Mj0iLTI5NS41NjgiIHkyPSI1MzEuNjk5IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiM4NEJDREEiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMDY3QkMyIi8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==')", backgroundRepeat: "no-repeat", backgroundSize: "contain", flexDirection: "column", justifyContent: "center"}}>
          <img src="../images/Catfight-SpecialEvents.png" className="phone-image" alt="A screenshot of Catfight showing upcoming special events" style={{marginTop: "80px"}}/>
        </div>
        <div className="max-width-container" style={{marginTop: "24px", paddingLeft: "12px", paddingRight: "12px"}}>
          <div className="headline-2">
            {"Special events highlight special kitties"}
          </div>
          <div className="content-text" style={{padding: "0px"}}>
            {"Special 24-hour long events highlight groups of kitties that may need a little more help getting the attention they deserve. From seniors to black cats, loners to long-term residents, each cat featured in battle during these events is just as deserving of a home as any other cat."}
          </div>
        </div>
      </div>

      <div className="full-width-container" style={{backgroundColor: "#EFF1F3", marginTop: "300px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "100px", paddingTop: "48px", paddingBottom: "48px"}}>
        <div className="headline-2" style={{textAlign: "center"}}>
          {"Download Catfight today"}
        </div>
        <div className="store-buttons-row" style={{marginTop: "36px"}}>
          <a href="https://apps.apple.com/us/app/catfight/id1502853247?ls=1">
            <img src="../images/Catfight-AppStore.png" className="store-button-left" alt="Download on the App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.mg.catfight">
            <img src="../images/Catfight-GooglePlay.png" className="store-button-right" alt="Get it on Google Play" />
          </a>
        </div>
      </div>

      <div className="full-width-container" style={{backgroundColor: "#FFFFFF", marginBottom: "100px"}}>
        <div className="max-width-container" style={{marginTop: "24px"}}>
          <div className="content-text">
            {"Apple and the Apple Logo are registered trademarks of Apple Inc. Google Play and the Google Play logo are trademarks of Google LLC."}
          </div>
        </div>
      </div>
    </div>
  )
};

export default MobileDevelopmentCourse;
