import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    //Catch errors in any components below and re-render with error message
    console.log(error);
    console.log(errorInfo);
    this.setState({
      error: error.friendly ? error.friendly : error.toString(),
      errorInfo: errorInfo,
    });
  }

  clearError() {
    this.setState({
      error: null,
      errorInfo: null,
    });
  }

  render() {
    if (this.state.errorInfo) {
      //Error path
      return (
        <div className="ao-game-container">
          <div className="ao-game-inner-container">
            <div className="ao-lobby-container">
              <div className="ao-lobby-inner-container">
                <div className="ao-headline">
                  {"It's always risky to attempt to communicate with the Other World"}
                </div>
                <div className="ao-text">
                  {this.state.error}
                </div>
              </div>
            </div>
            <div className="ao-primary-button" onClick={() => this.clearError()}>
              <div className="ao-primary-button-text">
                {"Start Over"}
              </div>
            </div>
          </div>
        </div>
      );
    }
    //Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
